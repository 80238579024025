import React, {useState} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Box,
} from "@chakra-ui/react"
import Login from "./Pages/Login";
import Profile from "./Pages/Profile";
import { useAuth } from "./Hooks/useAuth";
import Layout from "./OikosDesignSystem/Layout/Layout";
import {Search} from "./Pages/Search";
import {EducatorDisplayPage} from "./Pages/EducatorDisplayPage";
import Sessions from "./Pages/Sessions";
import ConversationsPage from "./Pages/ConversationsPage";
import Schedule from "./Pages/Schedule";
import SettingsPage from "./Pages/SettingsPage";
import AvatarImage from "./Components/AvatarImage";
import SignUpSuccess from "./Pages/SignUpSuccess";
import PasswordReset from "./Pages/PasswordReset";
import ConfirmPasswordReset from "./Pages/ConfirmPasswordReset";
import GoogleCallback from "./Pages/GoogleCallback";
import HomePageBanner from "./Pages/HomePageBanner";
import {VirtualAssistant} from "./Pages/VirtualAssistant";
import {MyDashboard} from "./Pages/MyDashboard";

export const App = () => {
  const {isLoading, isAuthenticated, oikosUser} = useAuth();

  if (isLoading) {
    return <Box />;
  }

  if (isAuthenticated) {
    console.debug("Authenticated user: ", oikosUser.DisplayName)
    return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path={"/educator/:EducatorID"} element={<EducatorDisplayPage />}></Route>
          <Route path={"/educatorPreview/:EducatorID"} element={<EducatorDisplayPage preview={true}/>}></Route>
          {/*<Route path={"/learnerPreview/:LearnerID"} element={<LearnerDisplayPage preview={true}/>}></Route>*/}
          <Route path="/search" element={<Search />}/>

          <Route path={"/sessions"} element={<Sessions />} />
          <Route path={"/conversations"} element={<ConversationsPage />} />
          <Route path={"/inbox"} element={<ConversationsPage />} />
          <Route path="/schedule" element={<Schedule/>}/>
          <Route path="/settings" element={<SettingsPage/>}/>
          <Route path="/virtual-assistant" element={<VirtualAssistant/>}/>
          <Route path="/my-dashboard" element={<MyDashboard/>}/>
          <Route path="/google-callback" element={<GoogleCallback/>}/>
          <Route path="*" element={<HomePageBanner/>}/>
          <Route path="/home" element={<HomePageBanner/>}/>
          {/*<Route path="*" element={<Profile/>}/>*/}
          <Route path="/profile" element={<Profile/>}/>
          <Route path={"profileAvatar/:OikosUserID"} element={<AvatarImage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <Layout >
          <Routes>
            <Route path={"/passwordreset"} element={<PasswordReset/>}/>
            <Route path={"/confirmpasswordreset"} element={<ConfirmPasswordReset/>}/>
            <Route path={"/signup/success"} element={<SignUpSuccess/>}/>
            <Route path="*" element={<Login/>}/>
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  }

  return <Box />

}
