import {Flex, Image} from "@chakra-ui/react";
import React from "react";


const OikosSideDecoration = () => {
  return (
    <Flex
      w={"424px"}
      h={"100%"}
      bg={"primary-color"}
      position={"absolute"}
      top={0}
      left={"1020px"}
      // opacity={0.1}
      zIndex={0}
    >

      <Image src={"/DarkTriangle.svg"}
             position={"relative"}
             top={"0"}
             height={"424px"}
      />
      <Image src={"/YellowCircle.svg"}
             position={"absolute"}
             top={"240px"}
             height={"424px"}
      />
      <Image src={"/RedTriangle.svg"}
             position={"absolute"}
             top={"424px"}
             height={"424px"}
      />
      <Image src={"/DarkTriangle.svg"}
             position={"absolute"}
             top={"848px"}
             height={"424px"}
      />

    </Flex>
  )
}

export default OikosSideDecoration
