import React, {useState} from 'react';
import {
  GridItem,
  Card,
  CardBody,
  HStack,
  Stack,
  Flex,
  IconButton,
  Checkbox,
  Grid,
  Text,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import OikosButton from "../OikosDesignSystem/OikosButton";
import {FormInput} from "../OikosDesignSystem/Components/FormInput";
import {DeleteIcon, EditIcon} from "@chakra-ui/icons";

type Props = {
  user: OIKOS.user
  updateFunction: () => void
}

const EducationManager: React.FC<Props> = ({user, updateFunction}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [educations, setEducations] = useState<OIKOS.Education[]>(user.Profile.Education || []);
  const [currentEducation, setCurrentEducation] = useState<OIKOS.Education>({Degree: '', Institution: '', Major: '', GraduationYear: 1900, CurrentlyEnrolled: false});
  const [currentEducationIndex, setCurrentEducationIndex] = useState<number | null>(null);
  const [drawerMode, setDrawerMode] = useState<string>("add");

  const handleSave = () => {
    if (currentEducationIndex !== null) {
      // Update the education at the current index
      let updatedEducations = [...educations];
      updatedEducations[currentEducationIndex] = currentEducation;
      setEducations(updatedEducations);
      user.Profile.Education = updatedEducations;
    } else {
      // Add a new education
      setEducations([...educations, currentEducation]);
      user.Profile.Education = [...educations, currentEducation];
    }
    setCurrentEducation({Degree: '', Institution: '', Major: '', GraduationYear: 1900, CurrentlyEnrolled: false});
    updateFunction();
    onClose();
  };

  const handleDelete = (index: number) => {
    let newEducations = (prevEducations: OIKOS.Education[]) => prevEducations.filter((_: OIKOS.Education, i: number) => i !== index)
    let updatedEducations = newEducations(educations);
    setEducations(updatedEducations);
    user.Profile.Education = updatedEducations;
    updateFunction()
  };


  const handleAddEducation = () => {
    setCurrentEducation({Degree: '', Institution: '', Major: '', GraduationYear: 1900, CurrentlyEnrolled: false});
    setDrawerMode("add");
    setCurrentEducationIndex(null);
    onOpen();
  }

const handleEditEducation = (index: number) => {
  setCurrentEducationIndex(index);
  let education = educations[index];
  setDrawerMode("edit");
  setCurrentEducation(education);
  onOpen();
}

  const isEducator = () => {
    return user.UserType === "EDUCATOR";
  }


  return (
    <Grid templateColumns="repeat(9, 1fr)">
      {educations.map((education, index) => (
        <GridItem colSpan={9} pt={4}>
          <Card
            direction={{base: 'column', sm: 'row'}}
            bg={"gray.100"}
            overflow='hidden'
            variant='outline'
          >
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Stack>
                <CardBody>
                  <Text fontSize={"32"} fontWeight={"bold"}>{education.Degree} | {education.Institution}</Text>

                  <HStack>
                    <Text fontSize={"20"} fontWeight={"bold"}>
                      { isEducator() ? "Subject" : "Major"}
                    </Text>
                    <Text fontSize={"20"}>
                      {education.Major}
                    </Text>
                  </HStack>
                  {education.CurrentlyEnrolled ?
                    <Text size={"20"} fontWeight={"bold"}>Currently Enrolled</Text>
                    :
                    <HStack>
                      <Text fontSize={"20"} fontWeight={"bold"}>
                        Graduation Date
                      </Text>
                      <Text fontSize={"20"}>
                        {education.GraduationYear}
                      </Text>
                    </HStack>
                  }

                </CardBody>
              </Stack>
              <HStack bg={"gray.400"} px={4}>
                <IconButton
                  aria-label="Edit"
                  icon={<EditIcon/>}
                  onClick={() => handleEditEducation(index)}
                />
                <IconButton
                  aria-label="Delete"
                  icon={<DeleteIcon/>}
                  onClick={() => handleDelete(index)}
                />
              </HStack>
            </Flex>
          </Card>
        </GridItem>
      ))}

      <GridItem pt={"42px"} colSpan={3} h={"130px"}>
        <OikosButton
          onClick={handleAddEducation}
          buttonText={"Add Education"}
        />
      </GridItem>

      <Drawer size="md" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton/>
            { drawerMode === 'add' ?
              <DrawerHeader>Add New Education</DrawerHeader> :
              <DrawerHeader>Edit Education</DrawerHeader>
            }

            <DrawerBody>
              <Grid gap={8}>
                <FormInput label="Degree" value={currentEducation.Degree}
                           onChange={(value) => setCurrentEducation({...currentEducation, Degree: value})}/>
                <FormInput label="Institution" value={currentEducation.Institution}
                           onChange={(value) => setCurrentEducation({...currentEducation, Institution: value})}/>


                <FormInput label={isEducator() ? "Subject" : "Major"}
                            value={currentEducation.Major}
                           onChange={(value) => setCurrentEducation({...currentEducation, Major: value})}/>

                <Checkbox
                  size={"lg"}
                  colorScheme="blue"
                  isChecked={currentEducation.CurrentlyEnrolled}
                  onChange={(e) => setCurrentEducation(prevState => ({...prevState, CurrentlyEnrolled: e.target.checked}))}>
                  <Text fontSize="md">Currently Enrolled</Text>
                </Checkbox>

                {!currentEducation.CurrentlyEnrolled && <FormInput type="number" label="Graduation Year" value={"" + currentEducation.GraduationYear}
                           onChange={(value) => setCurrentEducation({...currentEducation, GraduationYear: parseInt(value)})}/> }

              </Grid>
            </DrawerBody>

            <DrawerFooter>
              <HStack spacing={4}>
                {/*<OikosButton*/}
                {/*  variant={"outline"}*/}
                {/*  buttonText={"Cancel"}*/}
                {/*  onClick={onClose}*/}
                {/*/>*/}
                <OikosButton
                  buttonText={"Save"}
                  onClick={handleSave}
                />
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Grid>
  );
};

export default EducationManager;
