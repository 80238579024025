import React, {useEffect, useState} from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Input,
  Stack,
  HStack,
  Text,
  Tag, TagLabel, TagCloseButton
} from "@chakra-ui/react";
import { Page } from "../OikosDesignSystem/Layout/Page";
import { useOikos } from "../SDK/OikosContext";

import OikosInput from "../OikosDesignSystem/OikosInput";
import OikosButton from "../OikosDesignSystem/OikosButton";




export const VirtualAssistant = () => {

  const { oikos } = useOikos();
  const [userInput, setUserInput] = useState("");
  const [chatHistory, setChatHistory] = useState<{ sender: string; message: string }[]>([]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && userInput.trim() !== "") {
      const newChatHistory = [
        ...chatHistory,
        { sender: "user", message: userInput },
        { sender: "bot", message: generateBotResponse(userInput) },
      ];
      setChatHistory(newChatHistory);
      setUserInput("");
    }
  };

  const generateBotResponse = (input: string) => {
    // Simple bot response logic
    if (input.toLowerCase().includes("hello")) {
      return "Hello! How can I assist you today?";
    }
    return "I'm here to help!";
  };

  return (
    <Page title={"Virtual Advisor"} backgroundImage={"SearchBackground.svg"}>
      <Stack spacing={"75px"} top={"275px"}>
        <Stack
          spacing={"10px"}
          top={"275px"}
        >
          <Box
            padding={"20px"}
            rounded={"10px"}
            backgroundColor={"gray.50"}
          >
            {chatHistory.map((chat, index) => (
              <Text key={index} align={chat.sender === "user" ? "right" : "left"}>
                <strong>{chat.sender === "user" ? "You" : "Polis Virtual Advisor"}:</strong> {chat.message}
              </Text>
            ))}
          </Box>
            <Input
              placeholder={"Type your message..."}
              value={userInput}
              onInput={(e: any) => setUserInput(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <Button
              onClick={() => handleKeyPress({ key: "Enter" } as React.KeyboardEvent<HTMLInputElement>)}
              backgroundColor={"secondary-color-5"}
            >
              Send
            </Button>

        </Stack>

        <HStack>
          <OikosButton
            buttonText={"Social Impact Advisor"}
          />
          <OikosButton
            buttonText={"Summary of Last Sessions with Michael T"}
          />
          <OikosButton
            buttonText={"Summary of Last Sessions with Jose F"}
          />
        </HStack>

      </Stack>
    </Page>
  );
};
