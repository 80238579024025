import {Page} from "../OikosDesignSystem/Layout/Page";
import Settings from "../Settings/";
import OikosSideDecoration from "../OikosDesignSystem/OikosSideDecoration";
import {Flex} from "@chakra-ui/react";
import React from "react";


const SettingsPage: React.FC = () => {



  return (
    <Page title={"My Settings"}>
      <OikosSideDecoration/>
      <Flex zIndex={1}>
        <Settings />
      </Flex>
    </Page>
  )

}


export default SettingsPage;
