import {
  Avatar,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  Textarea, useDisclosure
} from "@chakra-ui/react";
import OikosButton from "../OikosDesignSystem/OikosButton";
import React, {useEffect, useState, useCallback} from "react";
import {useOikos} from "../SDK/OikosContext";
import Conversation from "./ConversationMessagesDisplay";
import {UserProfileBio, UserProfileHeader} from "../Components/UserProfile";
import {useNavigate} from "react-router-dom";

type Props = {
  conversation: OIKOS.Conversation
  oikosUser: OIKOS.user
  counterpartID: string
}

const ConversationApp: React.FC<Props> = ({conversation, oikosUser, counterpartID}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [conversationMessages, setConversationMessages] = useState<OIKOS.Message[]>([]);
  const [messageText, setMessageText] = useState<string>("");
  const {oikos} = useOikos();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [counterpart, setCounterpart] = useState<OIKOS.user>();

  const navigate = useNavigate()

  useEffect(() => {
    if (!loaded) {
      Promise.all([
        oikos.getUser(counterpartID),
        oikos.getConversationMessages(conversation.UniqueName)
      ])
        .then(([user, msgs]) => {
          setCounterpart(user);
          setConversationMessages(currMessages =>
            JSON.stringify(currMessages) !== JSON.stringify(msgs)
              ? msgs
              : currMessages);
          setLoaded(true);
        });
    }
  }, [counterpartID, oikos, loaded, conversation.UniqueName]);

  const onSendMessage = () => {
    oikos.sendMessage(oikosUser.ID, counterpartID, messageText)
      .then(() => {
        setMessageText("");
        oikos.getConversationMessages(conversation.UniqueName).then((msgs) => {
          setConversationMessages(msgs);
        });
      });
  };

  return (
    <Flex height={"100hv"} maxHeight={"100vh"} rounded={"20px"} background={"neutral-100"} shadow={"lg"}>
      <Drawer isOpen={isOpen} placement='right' onClose={onClose} size={['full', 'full', 'lg', 'xl', 'xl', 'xl']} >
        <DrawerContent scrollBehavior={"smooth"}>
          <DrawerBody >
            <Stack p={"38px"} spacing={8}>
              <HStack>
                {counterpart && <Avatar src={`data:image;base64,${counterpart!.Avatar}`} w={"125px"} h={"125px"} />}
                <UserProfileHeader oikosUser={counterpart!}/>
              </HStack>
              <UserProfileBio oikosUser={counterpart!}/>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {counterpart && <Stack p={"38px"}>
        <HStack py={"10px"} spacing={8}>
          <Avatar cursor={"pointer"} src={`data:image;base64,${counterpart!.Avatar}`} onClick={onOpen} w={"75px"} h={"75px"} />
          <Text fontSize={24} fontWeight={"bold"} cursor={"pointer"} onClick={onOpen}>{counterpart?.Name + " " + counterpart?.FamilyName} </Text>
          <Button
            onClick={() => {navigate("/educator/" + counterpart.ID)}}
          >Book a Session
          </Button>
        </HStack>
        <Conversation conversationMessages={conversationMessages} oikosUser={oikosUser}/>
        <Grid templateColumns='repeat(10, 1fr)' gap={4}>
          <GridItem colSpan={9} h={"75px"}>
            <Textarea w={"100%"} h={"100%"} value={messageText} onChange={(e) => setMessageText(e.target.value)} />
          </GridItem>
          <GridItem colSpan={1} h={"75px"}>
            <OikosButton buttonText={"Send"} h={"100%"} onClick={onSendMessage} />
          </GridItem>
        </Grid>
      </Stack>}
    </Flex>
  );
}

export default ConversationApp;
