import React from "react";
import {Box, Flex, Text} from "@chakra-ui/react";
import moment from "moment";

const ConversationMessage: React.FC<{message: OIKOS.Message, oikosUser: OIKOS.user}> = ({message, oikosUser}) => {

  let bgColor = "neutral-100"
  let justification = "start"
  let textColor = "neutral-800"
  let dateColor = "neutral-600"

  if (message.Author === oikosUser.ID) {
    bgColor = "secondary-color-1"
    textColor = "neutral-200"
    justification = "end"
    dateColor = "neutral-400"
  }

  if (message.Body === "") {
    return <></>
  }

  const date = moment(message.DateCreated)

  return (
    <Flex
      w={"100%"}
      justifyContent={justification}
    >
      <Box
        maxW={"350px"}
        width={"100%"}
        bg={bgColor}
        rounded={10}
        boxShadow={"xl"}
        border={"1px solid"}
        borderColor={"neutral-200"}
      >
        <Text p={"20px"} color={textColor}>{message.Body}</Text>
        <Text p={"10px"} align={"right"} color={dateColor} fontSize={12} >
          {date.format('MMMM Do, h:mm a')}
        </Text>
      </Box>
    </Flex>
  )

}

export default ConversationMessage;
