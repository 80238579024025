import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Text,
  Stack,
  useToast,
} from "@chakra-ui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";
import OikosButton from "../OikosDesignSystem/OikosButton";

const ConfirmResetPassword = () => {
  const [username, setUsername] = React.useState<string>("");
  const [code, setCode] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");
  const { confirmResetPassword } = useAuth();
  const navigate = useNavigate();

  const toast = useToast();

  const handleSubmit = async () => {

    confirmResetPassword(username, code, newPassword)
      .then((res) => {
        if (res.success) {
          toast({
            title: "Password Reset Successful",
            description: "Please login with your new password.",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          })
          navigate("/login"); // Redirect to login page after successful password reset
        } else {
          toast({
            title: "Error Resetting Password",
            description: "" + res.message,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        }
    }).catch((err) => {
      toast({
        title: "Error Resetting Password",
        description: "" + err,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    })


  };

  return (
    <Flex maxW="100%" py={{ base: "12", md: "24" }} justifyContent={"center"}>
      <Box w={640} pb={"80px"} bg={"neutral-100"} rounded={20} shadow={"md"}>
        <Flex
          flexDirection="column" // Stack children vertically
          alignItems="center"    // Centers children horizontally
          justifyContent="center" // Centers children vertically
          height="100%"          // Takes full available height
        >
        <Text pt={10} fontFamily={"lato"} color={"neutral-800"} fontWeight={"bold"} fontSize={"38"}>
          Confirm Password Reset
        </Text>
        <Stack spacing={5} width={470} justifyContent={"center"}>
          <FormControl pt={10}>
            <Input
              h={65}
              id="username"
              placeholder="Email"
              onChange={(e) => setUsername(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <Input
              h={65}
              id="code"
              placeholder="Reset Code"
              onChange={(e) => setCode(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <Input
              h={65}
              id="newPassword"
              placeholder="New Password"
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>
          <OikosButton buttonText={"Confirm Reset"} onClick={handleSubmit} />
        </Stack>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ConfirmResetPassword;
