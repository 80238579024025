import React, { useState } from "react";
import {useOikos} from "../SDK/OikosContext";
import {useParams} from "react-router-dom";


export const AvatarImage = () => {
  const [loaded, setLoaded] = useState(false);
  const [avatar, setAvatar] = useState<string>("");
  //
  const {OikosUserID} = useParams();

  const {oikos} = useOikos();

  if(!loaded) {

    oikos.getUser(OikosUserID!).then((user) => {
      setAvatar(`data:image;base64,${user.Avatar}`);
      setLoaded(true);
    });
  }


  return (
    <img src={avatar}></img>
  )
}

export default AvatarImage;
