import React, {useEffect, useState} from "react";
import {

  Center,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tag, TagLabel, TagCloseButton
} from "@chakra-ui/react";

import { Page } from "../OikosDesignSystem/Layout/Page";
import { useOikos } from "../SDK/OikosContext";
import EducatorCard from "../Components/EducatorCard";
import OikosInput from "../OikosDesignSystem/OikosInput";
import {listOfDisciplines, listOfOfficeHours, listOfLanguages} from "../oikosConfig";
import {FormSelect} from "../OikosDesignSystem/Components/FormSelect";
import {getLabel} from "../Utils/Utils";



export const Search = () => {
  const [educators, setEducators] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  // const [searchTerms, setSearchTerms] = useState<string[]>([]);

  const { oikos } = useOikos();
  const itemsPerPage = 12;
  const pages = Math.ceil(educators.length / itemsPerPage);
  const rangeEducators = educators.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  const [filters, setFilters] = useState<string[]>([]);
  const [searchTerms, setSearchTerms] = useState<string[]>([]);



  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let searchTermsString = event.currentTarget.value
      const terms = searchTermsString.split(" ")
      updateSearchTerms(terms);
    }
    // let searchTermsString = event.currentTarget.value
    // if (!['Enter', 'Alt', 'Delete', 'F5', 'Ctrl'].includes(event.key)) {
    //   if (event.key === 'Backspace') {
    //     searchTermsString = searchTermsString.slice(0, -1)
    //   } else {
    //     searchTermsString = searchTermsString + event.key;
    //   }
    //
    // }
    // const terms = searchTermsString.split(" ")
    // updateSearchTerms(terms)
  };

  const updateSearchTerms = (terms: string[]) => {
    setLoading(true);

    oikos.searchEducators(terms, filters).then((newEducators) => {
      if (typeof newEducators === "string")
      {
        setSearchTerms(terms)
        setEducators([]);
        setLoading(false);
      } else {
        setSearchTerms(terms)
        setEducators(newEducators);
        setLoading(false);
      }

    });
  };

  // Trigger an empty search on mount
  useEffect(() => {
    updateSearchTerms(searchTerms);
  }, []);


  useEffect(() => {
    setLoading(true);
    oikos.searchEducators(searchTerms, filters).then((newEducators) => {
      setEducators(newEducators);
      setLoading(false);
    });
  }, [filters]);

  const handleRemoveFilter = (index: number) => {
    let newLabels = filters.filter((_, i) => i !== index);
    setFilters(newLabels);
  };


  return (
    <Page title={"Find Advisors"} backgroundImage={"SearchBackground.svg"}>
      <Stack spacing={"75px"} top={"275px"}>
        <OikosInput
          placeholder={"What are you searching for?"}
          onKeyPress={handleKeyPress}
        />
        <Grid templateColumns={'repeat(26, 1fr)'}>
          <GridItem colSpan={8}>
          <FormSelect
            label={"Type of Advising Help"}
            border={"1px"}
            options={listOfOfficeHours.map((value: any, index: number) => {
              return [value.key, value.label]
            })}
            onChange={(value: any) => {
              let newFilters = [...filters, value]
              setFilters(newFilters)
            }}
          />
          </GridItem>
          <GridItem colSpan={1}/>
          <GridItem colSpan={8}>
          <FormSelect
            label={"Disciplinary Backgrounds"}
            border={"1px"}
            options={listOfDisciplines.map((value: any, index: number) => {
              return [value.key, value.label]
            })}
            onChange={(value: any) => {
              let newFilters = [...filters, value]
              setFilters(newFilters)
            }}
          />
          </GridItem>
          <GridItem colSpan={1}/>
          <GridItem colSpan={8}>
          <FormSelect
            label={"Languages"}
            border={"1px"}
            options={listOfLanguages.map((value: any, index: number) => {
              return [value.key, value.label]
            })}
            onChange={(value: any) => {
              let newFilters = [...filters, value]
              setFilters(newFilters)
            }}
          />
          </GridItem>
          <GridItem colSpan={26} h={8}/>
          <GridItem colSpan={26}>
            {filters.map((label, index) => (
              <Tag key={index} size="xlg"  mr={2} mt={2} p={4} bg={"white"} border={"1px"}>
                <TagLabel>{getLabel(label)}</TagLabel>
                <TagCloseButton onClick={() => handleRemoveFilter(index)} />
              </Tag>
            ))}
          </GridItem>

        </Grid>

        {loading ? (
          <Text>Loading...</Text>
        ) : (
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            {rangeEducators.map((educator: any) => (
              <EducatorCard educator={educator} />
            ))}
          </Grid>
        )}
        {/* Pagination controls */}
        <Center top={"45px"}>
          <RadioGroup onChange={(val) => setPage(Number(val))} value={page.toString()}>
            <Stack direction="row">
              {
                Array(pages).fill(0).map((_, i) => {
                  return (
                    <Radio
                      value={(i + 1).toString()}
                      // Other radio styling
                    >
                      <Text>{i + 1}</Text>
                    </Radio>
                  );
                })
              }
            </Stack>
          </RadioGroup>
        </Center>
      </Stack>
    </Page>
  );
};
