import React, {useState} from "react";
import { useParams} from "react-router-dom";
import {Page} from "../OikosDesignSystem/Layout/Page";
import {useOikos} from "../SDK/OikosContext";
import {
  Avatar, Flex,
  Grid, GridItem,
  HStack, Image, Stack,
  Text, Box,
} from "@chakra-ui/react";
import BookSessionForm from "../Components/Forms/BookSessionForm";

import {
  UserProfileHeader,
  UserProfileBio
} from "../Components/UserProfile";
import OikosSideDecoration from "../OikosDesignSystem/OikosSideDecoration";
import {getLanguageLabel, getOfficeHourLabel, getLabel} from "../Utils/Utils";


// Takes a string and capitalizes its first letter
const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}


type Props = {
  preview?: boolean;
}



export const EducatorDisplayPage: React.FC<Props> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [educator, setEducator] = useState<OIKOS.user>();
  const {EducatorID} = useParams();
  const {oikos} = useOikos();

  let preview = false;

  if (props.preview) {
    preview = props.preview;
  }


  if(!loaded) {
    //Load Educator
    oikos.getEducator(EducatorID || "").then((educator: any) => {
      console.log(educator)
      if (educator.EducatorProfile.Categories === null) {
        educator.EducatorProfile.Categories = [];
      }
      setLoaded(true);
      setEducator(educator);
     })
  }

  if (!loaded) {
    return (<Page title={""}> Loading ... </Page>)
  }

  //Watermark config
  const cols = 5;
  const rows = 10;
  const watermarkText = "Preview"

  return (
    <Page title={""} >
      <OikosSideDecoration/>

      <Flex zIndex={1}>
        {/* This is the Preview watermark*/}
        {preview && <Grid
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          templateRows={`repeat(${rows}, 1fr)`}
          templateColumns={`repeat(${cols}, 1fr)`}
          color="red"
          opacity="0.1"
          zIndex="-1"
        >
          {Array(rows * cols).fill(
            <Text
              fontSize="34px"
              textAlign="center"
              p={40}
            >
              {watermarkText}
            </Text>
          )}
        </Grid> }
      <Grid templateColumns={"repeat(14,1fr)"} w={"100%"} h={"100%"} gridRowGap={"24px"}>
        {/* left Column*/}
        <GridItem colSpan={8} pr={"65px"}>
          {/* eslint-disable-next-line react/jsx-no-undef */}
          <Grid templateColumns={"repeat(7,1fr)"} w={"100%"}>
          <GridItem colSpan={2} w={"100%"} h={"100%"} pb={"22px"}>
            <Avatar
              w={"200px"}
              h={"200px"}
              rounded={20}
              name={educator!.DisplayName}
              src={`data:image;base64,${educator!.Avatar}`}
            />
          </GridItem>

           <GridItem colSpan={5} pb={"22px"} >
             <UserProfileHeader oikosUser={educator!}/>
          </GridItem>

            <GridItem colSpan={7}>
              <Box background={"neutral-400"} width={"100%"} height={"2px"} />
            </GridItem>


            <GridItem pt={"22px"} pb={"50px"} colSpan={7}>
              <UserProfileBio oikosUser={educator!}/>
            </GridItem>


            <GridItem colSpan={4} pb={"25px"}>
              <Stack spacing={50}>
                { educator!.Profile.Title && <HStack alignItems={"top"}>
                  <Image height={"32px"} width={"32px"} src={"/User.svg"} />
                  <Stack>
                    <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={24} color={"neutral-800"}>
                      Title
                    </Text>
                    <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={18} color={"neutral-600"}>
                      {capitalize(educator!.Profile.Title)}
                    </Text>
                  </Stack>
                </HStack>}

                {educator!.Profile.Education && <HStack alignItems={"top"}>
                  <Image height={"32px"} width={"32px"} src={"/Badge.svg"}/>
                  <Stack>
                    <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={24} color={"neutral-800"}>
                      Education
                    </Text>
                    {educator!.Profile.Education.map((education: OIKOS.Education) => <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={18} color={"neutral-600"}>
                      {education.Institution} - {capitalize(education.Degree)}
                    </Text>)}
                  </Stack>
                </HStack> }

                {educator!.Profile.Experience && <HStack alignItems={"top"}>
                  <Image height={"32px"} width={"32px"} src={"/Badge.svg"}/>
                  <Stack>
                    <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={24} color={"neutral-800"}>
                      Experience
                    </Text>
                    {educator!.Profile.Experience.map((experience: OIKOS.Experience) => <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={18} color={"neutral-600"}>
                      {experience.Organization} - {capitalize(experience.Position)}
                    </Text>)}
                  </Stack>
                </HStack>}

              </Stack>
            </GridItem>

            <GridItem colSpan={3} pb={"25px"}>
              <Stack spacing={50}>
                {educator!.Profile.Languages &&  <HStack alignItems={"top"}>
                <Image height={"32px"} width={"32px"} src={"/Chat.svg"}/>
                <Stack>
                  <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={24} color={"neutral-800"}>
                    Spoken Languages
                  </Text>
                  {educator!.Profile.Languages.map((lang: string) => {
                    let label = getLanguageLabel(lang)
                    if ( label === "") {
                      return (<></>)
                    }
                    return (
                      <HStack><Image width={"10px"} src={"/Vector.svg"} />
                      <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={18} color={"neutral-600"}>
                        {capitalize(lang)}
                      </Text></HStack>
                    )}
                  )}
                </Stack>
              </HStack>}

                {educator!.EducatorProfile.Categories && <HStack alignItems={"top"}>
                  <Image height={"32px"} width={"32px"} src={"/Clock.svg"}/>
                  <Stack>
                    <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={24} color={"neutral-800"}>
                      How I Can Help
                    </Text>
                    <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={18} color={"neutral-600"}>
                      {educator!.EducatorProfile.Categories
                        .map((category: string) => {
                          let label = getLabel(category)
                          if ( label === "") {
                            return (<></>)
                          }
                          return (<HStack><Image width={"10px"} src={"/Vector.svg"}/>
                              <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={18} color={"neutral-600"}>
                                {label}
                              </Text></HStack>
                          )})
                      }
                    </Text>
                  </Stack>
                </HStack> }

                {educator!.EducatorProfile.Disciplines && <HStack alignItems={"top"}>
                  <Image height={"32px"} width={"32px"} src={"/Chat.svg"}/>
                  <Stack>
                    <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={24} color={"neutral-800"}>
                      Fields
                    </Text>
                    {educator!.EducatorProfile.Disciplines.map((item: string) => {
                      let label = getLabel(item)
                      if ( label === "") {
                        return (<></>)
                      }
                      return (
                        <HStack><Image width={"10px"} src={"/Vector.svg"} />
                          <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={18} color={"neutral-600"}>
                            {capitalize(label)}
                          </Text></HStack>
                      )}
                    )}
                  </Stack>
                </HStack>}

              </Stack>
            </GridItem>

          </Grid>
        </GridItem>

        {/* right Column*/}
        <GridItem colSpan={6}>
          <BookSessionForm
            educator={educator!}
            preview={preview}
          />
        </GridItem>

      </Grid>
      </Flex>
    </Page>
  )
}
