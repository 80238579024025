import {
  Box,
  Drawer, DrawerBody,
  DrawerContent, DrawerFooter,
  DrawerHeader, Grid, GridItem,
  HStack,
  Stack,
  Text, Textarea,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import React, {useEffect} from "react";
import OikosButton from "../OikosDesignSystem/OikosButton";
import moment from "moment";
import {useOikos} from "../SDK/OikosContext";
import {useAuth} from "../Hooks/useAuth";
import Conversation from "../Conversations/ConversationMessagesDisplay";


type Props = {
  startDate: string;
  duration: number;
  educator: OIKOS.user;
  preview?: boolean;
}


const BookSessionDrawer: React.FC<Props> = ({startDate, duration, educator, preview = false}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [conversationMessages, setConversationMessages] = React.useState<OIKOS.Message[]>([]);
  const [initialMessage, setInitialMessage] = React.useState<string>("");

  const date = moment(startDate)
  const time = date.format("h:mm")
  const ampm = date.format("a")

  const toast = useToast();
  const {oikos} = useOikos();
  const {oikosUser} = useAuth();


  const isNewConversation = () => {
    return conversationMessages.length === 0;
  }

  //If the booking button need to be enabled/disabled
  const bookingEnabled = () => {
    if (isNewConversation()) {
      if (initialMessage === "") {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  useEffect(() => {
    const uniqueName = educator.ID + oikosUser?.ID;
    oikos.getConversationMessages(uniqueName)
      .then((msgs) => {
        setConversationMessages(msgs)
        setLoaded(true);
      })
      .catch((err) => {
        console.log("This is a new conversation");
      })
  }, [educator]);


  return (
    <>
      <HStack as="label"
              align={"center"}
              bg={"neutral-100"}
              borderRadius="md"
              boxShadow="md"
              onClick={onOpen}
              width={"95%"}
      >
        <Box
          cursor="pointer"
          borderWidth="1px"
          _checked={{
            bg: 'teal.600',
            color: 'white',
            borderColor: 'teal.200',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          width={"100%"}
          maxW={"100%"}
          px={"5px"}
          py={"2px"}
        >
          <HStack spacing={"2px"}>
            <Text fontWeight={"extrabold"} fontSize={14}>{time}</Text>
            {/*<Box height={"1px"} width={"100%"} bg={"neutral-600"}/>*/}
            <Text fontWeight={"light"} fontSize={10}>{ampm}</Text>
          </HStack>
        </Box>
      </HStack>

      {!preview && <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={['full', 'full', 'lg', 'xl', 'xl', 'xl']}
      >
        {/*<DrawerOverlay />*/}

        <DrawerContent scrollBehavior={"smooth"}>
          <DrawerHeader>
            <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={24} color={"neutral-800"}>
              Book a Session with {educator.Name}
            </Text>
          </DrawerHeader>
          <DrawerBody>
            {/*{props.children}*/}
            <Stack p={"38px"} spacing={"8px"} h={"100%"}>
              <HStack>
                <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={16} color={"neutral-600"}>
                  Date:
                </Text>
                <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={16} color={"neutral-800"}>
                  {date.format("dddd, MMMM Do YYYY")}
                </Text>
              </HStack>
              <HStack>
                <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={16} color={"neutral-600"}>
                  Time:
                </Text>
                <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={16} color={"neutral-800"}>
                  {date.format("h:mm a")}
                </Text>
                <Text fontFamily={"lato"} fontWeight={"light"} fontSize={16} color={"neutral-800"}>
                  {oikosUser.Profile.Timezone}
                </Text>
              </HStack>
              <HStack>
                <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={16} color={"neutral-600"}>
                  Duration:
                </Text>
                <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={16} color={"neutral-800"}>
                  {duration}
                </Text>
                <Text fontFamily={"lato"} fontWeight={"light"} fontSize={16} color={"neutral-800"}>
                  minutes
                </Text>
              </HStack>

              {/*<HStack>*/}
              {/*  <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={16} color={"neutral-600"}>*/}
              {/*    Price:*/}
              {/*  </Text>*/}
              {/*  <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={16} color={"neutral-800"}>*/}
              {/*    {educator.EducatorProfile.Price.Amount * duration / 6000} {educator.EducatorProfile.Price.Currency}*/}
              {/*  </Text>*/}
              {/*  <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={16} color={"neutral-600"}>*/}
              {/*    {educator.EducatorProfile.Price.Currency}*/}
              {/*  </Text>*/}
              {/*</HStack>*/}

              <Box pt={"20px"}>
                {isNewConversation() ? (
                  <Stack spacing={8} pt={"50px"} h={"100%"}>
                    <Text
                      fontWeight={"bold"}
                      fontSize={18}
                    >Intake Message (Required)</Text>
                    <Text
                      fontWeight={"light"}
                      color={"neutral-600"}
                      fontSize={14}
                    >What do you want to do in your office hours session? Limit your session to one (1) specific,
                      achievable goal based on the amount of time you have reserved. Include any relevant information
                      related to your goal or need. If you wish to share a document (e.g. CV, personal statement),
                      please
                      include a URL link to the file that is accessible to your Personal Educator in your message.
                      Enjoy!</Text>
                    <Textarea
                      w={"100%"}
                      h={"100%"}
                      // size={"lg"}
                      value={initialMessage}
                      onChange={(e) => setInitialMessage(e.target.value)}
                    />
                  </Stack>
                ) : (
                  <>
                    <Text
                      fontWeight={"bold"} fontSize={18} pb={"10px"}
                    >
                      Previous Conversations:
                    </Text>
                    <Box
                      sx={{
                        maxHeight: "100%", // Set your desired maximum height here
                        overflowY: "auto", // Add scrollbars if the content exceeds the maximum height
                      }}
                      bg={"neutral-300"}
                      borderRadius={"20px"}
                      pt={"20px"}
                    >

                      <Conversation
                        conversationMessages={conversationMessages}
                        oikosUser={oikosUser}
                      />
                    </Box>
                  </>
                )
                }
              </Box>
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            {bookingEnabled() ? (
              <Stack width={"100%"}>
                <Text
                  fontWeight={"extrabold"} fontSize={18}
                >
                  What do you want to discuss in this session:
                </Text>
                <Textarea w={"100%"} h={"100%"} value={initialMessage}
                          onChange={(e) => setInitialMessage(e.target.value)}/>
                <OikosButton
                  buttonText={"Request to Book"}
                  onClick={() => {
                    console.log("Request to Book")
                    console.log(oikosUser)
                    oikos.sendMessage(oikosUser.ID, educator.ID, initialMessage).then(
                      () => {
                        oikos.sessionRequest(oikosUser.ID, educator.ID, startDate, duration)
                          .then(
                            (response) => {
                              console.log(response)
                              toast({
                                title: "Session Requested",
                                description: "Session Successfully Requested",
                                status: "success",
                                duration: 5000,
                                position: "top-right",
                                isClosable: true,
                              });
                              onClose()
                            }
                          )
                          .catch(
                            (error) => {
                              console.log(error)
                              toast({
                                title: "Session Request Failed",
                                description: "Session Request Failed",
                                status: "error",
                                duration: 5000,
                                position: "top-right",
                                isClosable: true,
                              });
                            }
                          )
                      })
                  }}
                />
              </Stack>) : (
              <OikosButton
                variant={"disabled"}
                buttonText={"Request to Book"}
              />
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>}
    </>
  )
}

export default BookSessionDrawer;
