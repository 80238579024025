import React from 'react';
import {Page} from "../OikosDesignSystem/Layout/Page";
import {Text} from "@chakra-ui/react";

const SignUpSuccess:React.FC = () => {

  return (
    <Page title={"Sign Up Successfull"} backgroundImage={"SearchBackground.svg"}>

      <Text>
        An email has been sent to the email address you provided.

        Please follow the instruction to complete the signup.
      </Text>

    </Page>
  )
}

export default SignUpSuccess;
