import {
  Box, Flex,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text
} from '@chakra-ui/react'
import {
  Page
} from "../OikosDesignSystem/Layout/Page";
import React, {useEffect} from "react";
import {useAuth} from "../Hooks/useAuth";
import AddAvailabilityBlockDrawer from "../Components/AddAvailabilityBlockDrawer";
import {useOikos} from "../SDK/OikosContext";
import OikosSideDecoration from "../OikosDesignSystem/OikosSideDecoration";


type SlotDisplayProps = {
  slot: OIKOS.ScheduleSlot
  onClick: () => void
}
const SlotDisplay:React.FC<SlotDisplayProps> = (props) => {
  const [selected, setSelected] = React.useState<boolean>(props.slot.Available)

  return (
    <GridItem>
      <Box
        cursor="pointer"
        boxShadow={"xl"}
        rounded={10}
        // _checked={{
        //   bg: 'teal.600',
        //   color: 'white',
        //   borderColor: 'teal.200',
        // }}
        // _focus={{
        //   boxShadow: 'outline',
        // }}
        bg={selected ? "primary-color" : "neutral-100"}
        color={selected ? "white" : "neutral-800"}
        px={"10px"}
        py={"2px"}
        onClick={() => {
          setSelected(!selected)
          props.onClick()
        }}
      >
        <Stack spacing={"2px"}>
          <Text>{String(props.slot.StartHour).padStart(2, '0')}:{String(props.slot.StartMinute).padStart(2, '0')}</Text>
          {/*<Box height={"1px"} width={"100%"} bg={"neutral-600"}/>*/}
          {/*<Text>{getTimeString(endTime)}</Text>*/}
        </Stack>
      </Box>
    </GridItem>
  )
}



const Schedule = () => {
  const [sched, setSched] = React.useState<OIKOS.ScheduleSlot[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const {oikosUser} = useAuth();
  const { oikos } = useOikos();

  const loadScheduleGrid = (schedule: OIKOS.ScheduleSlot[]) => {
    console.log("Loading schedule grid");
    let slots: JSX.Element[] = [];

    schedule.forEach((slot) => {
      const location = slot.DayOfWeek + (slot.StartHour * 2 + slot.StartMinute / 30) * 7;
      const pos = schedule.indexOf(slot);
      slots[location] = (
        <>
        <SlotDisplay
          slot={slot}
          onClick={() => {
            console.log(sched)
            console.log(pos)
            schedule[pos].Available = !schedule[pos].Available;
            setSched(schedule);
            oikos.updateEducatorSchedule(oikosUser.ID, schedule)
          }}
        />
        </>
      )
    })

    return slots;
  }

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const loadScheduleHeader = () => {
    let slots: JSX.Element[] = [];
    for (let i = 0; i < 7; i++) {
      slots.push(
        <GridItem>
          <Box
            boxShadow={"xl"}
            rounded={10}
            bg={"primary-color"}
            color={"neutral-100"}
            px={"10px"}
            py={"2px"}
          >
            <Stack spacing={"2px"}>
              {/*<Box height={"1px"} width={"100%"} bg={"neutral-600"}/>*/}
              <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={24}>{daysOfWeek[i]}</Text>
              <AddAvailabilityBlockDrawer
                UserID={oikosUser.ID}
                DayOfWeek={i}
                refresh={() => {setLoaded(false)}}
              />
            </Stack>
          </Box>
        </GridItem>
      )
    }
    return slots;
  }


  useEffect(() => {
    const fetchData = async () => {
      if (!loaded) {
        console.log("Fetching data");
        const user = await oikos.getUser(oikosUser.ID);
        setSched(user.EducatorProfile.Schedule);
        setLoaded(true);
      }
    };

    fetchData();
  }, [sched, loaded]);


  return (
    <>
      <Page title={"Your Schedule"}>
        <Flex zIndex={-1}>
          <OikosSideDecoration/>
        </Flex>
            <HStack>
              <Text fontSize={22} fontWeight={"bold"}>Timezone:</Text>
              <Text fontSize={22} fontWeight={"light"}>{oikosUser.Profile.Timezone}</Text>
            </HStack>
            <Text fontSize={14} fontWeight={"light"} pb={"35px"}>
              If you would like to change your timezone you might do so in your profile.
            </Text>

            <HStack>
              <Text fontSize={22} fontWeight={"bold"}>Minimum Booking Notice:</Text>
              <Text fontSize={22} fontWeight={"light"}>24 Hours</Text>
            </HStack>
            <Text fontSize={14} fontWeight={"light"} pb={"35px"}>
              This is will be customizable in the near future.
            </Text>

            <Text fontSize={18} fontWeight={"light"} pb={"35px"}>
              This is your default weekly schedule and it repeats every week. You can add or remove availability blocks by clicking on the day of the week.
            </Text>



            {loaded && <Grid
              templateColumns='repeat(7, 1fr)'
              gap={2}>
              {loadScheduleHeader()}
              {loadScheduleGrid(sched)}
            </Grid> }
      </Page>
    </>
  )


}

export default Schedule;
