import {Button} from "@chakra-ui/react";


const ProfileButtons = ({title, destinationRef}: any) => {


  const scrollToSection = () => {
    if (destinationRef !== null && destinationRef.current !== null) {
      destinationRef.current.scrollIntoView({behavior: "smooth"});
    } else {
      console.log("Destination Ref is null");
      console.log(destinationRef)
    }
  };


  return (
    <Button
      h={"70px"}
      w={"274px"}
      bg={"neutral-100"}
      fontFamily={"lato"}
      fontWeight={"bold"}
      fontSize={18}
      shadow={"md"}
      onClick={scrollToSection}
    >
      {title}
    </Button>
  )
}

export default ProfileButtons;
