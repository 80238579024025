import React, { useState } from 'react';
import {Table, Tbody, Tr, Td, Button, Flex, Text,} from "@chakra-ui/react";
import {AddIcon, DeleteIcon} from "@chakra-ui/icons";
import {FormSelect} from "../OikosDesignSystem/Components/FormSelect";
import {notification} from "antd";

type ListManagerProps = {
  list: any[];
  presetValues: any[];
  updateItems: (items: any[]) => void;
  maxItems?: number;
};


const ListManager: React.FC<ListManagerProps> = ({ list, presetValues, updateItems, maxItems }) => {
  const [items, setItems] = useState(list);
  const [options, setOptions] = useState(presetValues.filter(option => !list.includes(option)));
  const [selectedValue, setSelectedValue] = useState(options[0].key);

  const handleAddItem = () => {
    console.log("====================================")
    console.log(options)
    console.log(selectedValue)
    console.log(items)
    if (!maxItems) {
      maxItems = Number.MAX_SAFE_INTEGER
    }
    if (items.length >= maxItems) {
     notification.info({message: "You can only add up to 6 items"})
    } else {
      if (selectedValue && !items.includes(selectedValue)) {
        let newItems = [...items, selectedValue];
        setItems(newItems);
        updateItems(newItems)
        let newOptions = options.filter(option => option !== selectedValue)
        setOptions(newOptions);
        setSelectedValue(newOptions[0]);

      }
    }
  };

  const handleRemoveItem = (itemToRemove: any) => {
    let newItems = items.filter(item => item !== itemToRemove)
    setItems(newItems);
    updateItems(newItems);
    let newOptions = [...options, itemToRemove];
    setOptions(newOptions);
    setSelectedValue(newOptions[0]);
  };

  const getLabel = (key: string) => {
    let res = presetValues.find(presetValue => presetValue.key === key);
    if (!res) {
      let newItems = items.filter(item => item !== key)
      setItems(newItems);
      return key
    }

    return res["label"]
  }

  return (
    <Flex direction={"column"} height={"100%"} >
      <Table variant="simple">
        <Tbody>
          {items.map((item: any, index: number) => (
            <Tr key={index} bg={index % 2 === 0 ? "gray.100" : "white" }>
              <Td>
                  <Text fontFamily={"lato"} fontSize={18} fontWeight={"bold"} color={"neutral-800"} >
                      {getLabel(item)}
                  </Text>
              </Td>
              <Td>
                <Flex justifyContent="flex-end">
                  <Button
                    onClick={() => handleRemoveItem(item)}
                    background="none"
                    _hover={{ background: "none" }}
                    _active={{ background: "none" }}
                    border="none"
                  >
                    <DeleteIcon boxSize={8} color="neutral-600"/>
                  </Button>
                </Flex>
              </Td>
            </Tr>
          ))}
          <Tr>
            <Td px={0}>
              <FormSelect
                label={""}
                options={options.map((value: any, index: number) => {
                  return [value.key, value.label]
                })}
                onChange={(value: any) => setSelectedValue(value)}
              />
            </Td>
            <Td>
              <Flex justifyContent="flex-end">
                <Button
                  onClick={handleAddItem}
                  background="none"
                  _hover={{ background: "none" }}
                  _active={{ background: "none" }}
                  border="none"
                >
                  <AddIcon boxSize={8} color="neutral-600" />
                </Button>
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>

    </Flex>
  );
};


ListManager.defaultProps = {
  maxItems: Number.MAX_SAFE_INTEGER
}

export default ListManager;
