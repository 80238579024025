import React, { useEffect } from "react";
import {
  Input, Stack,
  Text,
} from "@chakra-ui/react";

type Props = {
  label?: string;
  onChange?: (value: string) => void;
  value?: string;
  type?: string;
}

export const FormInput: React.FC<Props> = ({ label, onChange, value, type }) => {
  const [localValue, setLocalValue] = React.useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <Stack spacing={"8px"}>
      {label && <Text fontFamily={"lato"} fontSize={18} fontWeight={"bold"} color={"neutral-800"}>
        {label}
      </Text>}
      <Input
        h={65}
        w={"100%"}
        value={localValue}
        onChange={handleChange}
        type={type}
      />
    </Stack>
  );
};
