import {Avatar, Box, Container, HStack, Stack, Text} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useOikos} from "../SDK/OikosContext";


type Props = {
  oikosUser: OIKOS.user;
  selected: boolean;
  conversation: OIKOS.Conversation;
  onConversationClick: (conv: any) => void;
  setCounterpartID: (counterpart: string) => void;
}

const ConversationButton: React.FC<Props> = ({ oikosUser, selected, conversation, onConversationClick, setCounterpartID }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [counterpart, setCounterpart] = useState<OIKOS.user>();
  const [messages, setMessages] = useState<OIKOS.Message[]>([]);


  const { oikos } = useOikos();

  const educatorID = conversation.UniqueName.substring(0, 36);
  const learnerID = conversation.UniqueName.substring(36, 72);
  const counterpartID = oikosUser.ID === educatorID ? learnerID : educatorID;


  const onClick = () => {
    onConversationClick(conversation);
    setCounterpartID(counterpartID);
  }

  useEffect(() => {
    oikos.getConversationMessages(conversation.UniqueName).then((msgs) => {
      setMessages(msgs)
    });

    if (!loaded) {
      oikos.getUser(counterpartID)
        .then((user) => {
          setCounterpart(user);
          setLoaded(true);
        });
    }
  }, [loaded, oikos, counterpartID]);

  let bg = selected ? "neutral-300" : "neutral-100";

  return (
    <>
      {loaded && <Box
        bg={bg}
        rounded={20}
        boxShadow={"lg"}
        onClick={onClick}
        _hover={{
          cursor: "pointer",
          bg: "neutral-300"
        }}
        maxW={"600px"}
      >
        <Container>
          <HStack py={"10px"} spacing={8}>
            <Avatar
              src={`data:image;base64,${counterpart?.Avatar}`}
              w={"75px"}
              h={"75px"}
            />
            <Stack>
              <Text fontSize={24} fontWeight={"bold"}>
                {counterpart?.Name + " " + counterpart?.FamilyName}
              </Text>
              <Text fontSize={12} fontWeight={"light"}>
                {messages.slice(-1)[0]?.Body}
              </Text>
            </Stack>
            {/*<Text fontSize={12} fontWeight={"light"}>*/}
            {/*  {messages.slice(-1)[0]?.DateCreated}*/}
            {/*</Text>*/}
          </HStack>
        </Container>
      </Box> }
    </>
  )
}

export default ConversationButton;
