import React, { useState } from 'react';
import { Input, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';


type Props = {
  labels: string[];
  updateLabels: (labels: string[]) => void;
}

const KeywordLabelManager: React.FC<Props> = (props) => {
  const [labels, setLabels] = useState<string[]>(props.labels);
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    console.log(value)
    if (value.includes(',')) {
      let newLabels = [...labels, value.slice(0, -1)]
      setLabels(newLabels);
      props.updateLabels(newLabels)
      setInputValue('');
    } else {
      setInputValue(value);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      let newLabels = [...labels, inputValue];
      setLabels(newLabels);
      props.updateLabels(newLabels)
      setInputValue('');
    }
  };

  const handleRemoveLabel = (index: number) => {
    let newLabels = labels.filter((_, i) => i !== index);
    setLabels(newLabels);
    props.updateLabels(newLabels)
  };

  return (
    <div>
      {labels.map((label, index) => (
        <Tag key={index} size="lg" m={2}>
          <TagLabel>{label}</TagLabel>
          <TagCloseButton onClick={() => handleRemoveLabel(index)} />
        </Tag>
      ))}
      <Input
        m={2}
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="Type and press comma or enter..." />

    </div>
  );
};

export default KeywordLabelManager;
