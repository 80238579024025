import {useOikos} from "../SDK/OikosContext";
import {Grid, GridItem, Stack, useToast} from "@chakra-ui/react";
import { useAuth } from "../Hooks/useAuth";
import ProfileButtons from "../Profile/ProfileButtons";
import React, {useRef} from "react";
import ProfileAvatar from "../Profile/ProfileAvatar";
import UpdatePasswordCard from "../Profile/UpdatePasswordCard";
import UpdateContactCard from "./UpdateContactCard";

const Settings = () => {
  const { oikosUser } = useAuth();
  let updateableUser = oikosUser;

  const changePasswordRef = useRef(null);
  const updateContactRef = useRef(null);

  return (
    <Grid templateColumns="repeat(12, 1fr)">
      {/*Left Column*/}
      <GridItem colSpan={3}>
        {/*<GridItem colSpan={3} position={"fixed"} height={"100vh"}>*/}
        <Stack spacing={"16px"} pt={"134px"}>
          <ProfileButtons title={"Change Password"} destinationRef={changePasswordRef} />
          <ProfileButtons title={"Update Contact Info"} destinationRef={updateContactRef} />
        </Stack>

      </GridItem>

      {/*Right Column*/}
      <GridItem colSpan={9} position={"relative"}>
        <ProfileAvatar
          user={updateableUser}
        />
        <Stack spacing={"24px"} pt={"70px"}>
          <UpdatePasswordCard ref={changePasswordRef}/>
          <UpdateContactCard ref={updateContactRef} />
        </Stack>
      </GridItem>
    </Grid>
  )

}

export default Settings;
