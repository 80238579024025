import React from "react";
import {
  Select, Stack,
  Text,
} from "@chakra-ui/react";

type Props = {
  label: string;
  onChange: (e: any) => void;
  value?: string;
  border?: string;
  options: [string, string][];
}

export const FormSelect = (props: Props) => {

  const [value, setValue] = React.useState(props.value)

  const onChange = (e: any) => {
    setValue(e.target.value)
    props.onChange(e.target.value)
  }

  return (
    <Stack spacing={"8px"} >
      <Text fontFamily={"lato"} fontSize={18} fontWeight={"bold"} color={"neutral-800"} >
        {props.label}
      </Text>
      <Select
        h={65}
        w={"100%"}
        value={value}
        bg={"white"}
        border={props.border}
        onChange={onChange}
      >
        props.options & {props.options.map( (item: [string, string]) => {
          return <option value={item[0]}>{item[1]}</option>
        })}
      </Select>
    </Stack>
  )

}
