import React, { useEffect } from 'react';
import { Page } from "../OikosDesignSystem/Layout/Page";
import {Grid, GridItem, Stack, Flex, useToast, Text, Textarea, Container, HStack, Avatar, Box} from "@chakra-ui/react";
import { useOikos } from "../SDK/OikosContext";
import { useAuth } from "../Hooks/useAuth";
import ConversationsButton from "../Conversations/ConversationButton";
import OikosSideDecoration from "../OikosDesignSystem/OikosSideDecoration";
import OikosButton from "../OikosDesignSystem/OikosButton";
import ConversationApp from "../Conversations/ConversationApp";

const ConversationsPage: React.FC = () => {
  const [conversations, setConversations] = React.useState<OIKOS.Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] = React.useState<OIKOS.Conversation>();
  const [selectedConversationMessages, setSelectedConversationMessages] = React.useState<OIKOS.Message[]>([]);
  const [selectedConversationCounterpartID, setSelectedConversationCounterpartID] = React.useState<string>("");
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const toast = useToast();
  const { oikos } = useOikos();
  const { oikosUser } = useAuth();

  useEffect(() => {
    if (!loaded) {
      oikos.getUserConversations(oikosUser.ID)
        .then((convs) => {
          setConversations(convs);
          setLoaded(true);
        })
        .catch((err) => {
          toast({
            title: "Error loading messages",
            description: "There was an error loading your messages. Please try again later.",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        });
    }
  }, [loaded, oikos, oikosUser.ID, toast]);

  return (
    <Page title={"Conversations"} backgroundImage={"SearchBackground.svg"}>
      <OikosSideDecoration/>
      <Flex zIndex={1}>
      {/*<Stack spacing={"10px"} width={"100%"}>*/}
      {/*  {*/}
      {/*    conversations.map((conv) => {*/}
      {/*      return <ConversationsDrawer key={conv.SID} conversation={conv} />; // Add a unique key prop*/}
      {/*    })*/}
      {/*  }*/}
      {/*</Stack>*/}
        <Grid templateColumns={"repeat(12,1fr)"} w={"100%"} h={"100%"} gridRowGap={"24px"}>
          <GridItem colSpan={5} pr={"65px"}>
            <Stack spacing={"10px"} width={"100%"}>
              {
                conversations.map((conv) => {
                  if (!selectedConversation) {
                    setSelectedConversation(conv);
                  }
                  return <ConversationsButton
                    key={conv.SID}
                    oikosUser={oikosUser}
                    conversation={conv}
                    onConversationClick={(conv) => {
                      setSelectedConversation(conv);
                    }}
                    selected={conv === selectedConversation}
                    setCounterpartID={setSelectedConversationCounterpartID}
                  />;
                })
              }
            </Stack>
          </GridItem>
          <GridItem colSpan={7}>
            {selectedConversation && <ConversationApp
              key={selectedConversation.SID}
              conversation={selectedConversation}
              // conversationMessages={selectedConversationMessages}
              oikosUser={oikosUser}
              counterpartID={selectedConversationCounterpartID}
            />}
          </GridItem>
        </Grid>
      </Flex>
    </Page>
  );
};

export default ConversationsPage;
