import {Box, Stack} from "@chakra-ui/react";
import React, {useEffect, useRef} from "react";
import ConversationMessage from "./ConversationMessage";


const ConversationMessagesDisplay: React.FC<{
  conversationMessages: OIKOS.Message[]
  oikosUser: OIKOS.user
}> = ({conversationMessages, oikosUser}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [conversationMessages]); // Scroll to the bottom whenever conversationMessages change


  return (
    <Box ref={containerRef} h={"100%"} p={"20px"} overflowY="auto">
      <Stack spacing={4} h={"50vh"} >
        {
          conversationMessages.map((msg) => {
            return (
              <ConversationMessage message={msg} oikosUser={oikosUser}/>
            )
          })
        }
      </Stack>
    </Box>
  )

}

export default ConversationMessagesDisplay;
