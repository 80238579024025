import {Stack, Text} from "@chakra-ui/react";
import React from "react";
import {
  displayPronouns,
  splitBioIntoParagraphs
} from "../Utils/Utils";


type Props = {
  oikosUser: OIKOS.user
}


export const UserProfileHeader:React.FC<Props> = ({oikosUser}) => {
  return (
    <Stack pt={"20px"} pl={"36px"}>
      <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={38} color={"neutral-800"}>
        {oikosUser.DisplayName}
      </Text>
      {(oikosUser.UserType === "EDUCATOR") &&
        <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={24} color={"primary-color-1"}>
          {oikosUser.EducatorProfile.CurrentPosition}, {oikosUser.EducatorProfile.CurrentOrganization}
        </Text>
      }
      <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={20} color={"neutral-500"}>
        {displayPronouns(oikosUser.Profile.Pronouns)}
      </Text>
    </Stack>
  )
}

export const UserProfileBio:React.FC<Props> = ({oikosUser}) => {
  return (
    <Stack spacing={"14px"}>
      <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={24} color={"neutral-800"}>
        About  {oikosUser.Name}
      </Text>
      {splitBioIntoParagraphs(oikosUser!.Profile.Bio).map(paragraph =>
        <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={18} color={"neutral-600"}>{paragraph}</Text>)
      }
    </Stack>
  )
}

