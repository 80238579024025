import { useState } from "react";
import {
  Button,
  HStack
} from "@chakra-ui/react";
import OikosButton from "../OikosDesignSystem/OikosButton";

interface RadioButtonProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
}

const OikosRadioButton = ({options, value, onChange}: RadioButtonProps) => {

  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (newValue: string) => {
    setSelectedValue(newValue);
    onChange(newValue);
  };


  return (
    <HStack>
      {options.map((option) => (
        <OikosButton
          width={"100%"}
          height={"48px"}
          buttonText={option}
          variant={option === selectedValue ? "solid" : "outline"}
          // colorScheme={option === selectedValue ? "primary-color" : undefined}
          onClick={() => handleChange(option)}
        >
          {option}
        </OikosButton>
      ))}
    </HStack>
  );
}

export default OikosRadioButton;
