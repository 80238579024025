import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {useOikos} from "../SDK/OikosContext";
import {useAuth} from "../Hooks/useAuth";

const GoogleCallback: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  const {oikosUser} = useAuth();
  const {oikos}  = useOikos()

  console.log('GoogleCallback rendered');

  useEffect(() => {
    console.log('Route changed', location); // Add this line
    if (code) {
      alert("Setting the google token");
      oikos.setGoogleToken(oikosUser.ID, code);
    }
  }, [location.pathname]); // Listen to changes in the route

  return (
    <div>
      <h1>GoogleCallback</h1>
      <p>Code: {code}</p>
    </div>
  );
}

export default GoogleCallback;
