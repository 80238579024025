import {Stack, useToast} from "@chakra-ui/react";
import OikosButton from "../OikosDesignSystem/OikosButton";
import React from "react";
import {useOikos} from "../SDK/OikosContext";
import {useAuth} from "../Hooks/useAuth";


const SessionsButtonsEducator:React.FC<{
  session: OIKOS.Session,
  closeDrawer: () => void,
}> = ({session, closeDrawer}) => {

  const {oikosUser} = useAuth();
  const toast = useToast()
  const {oikos} = useOikos();

  const roomName = session.Educator + session.Learner;
  return (
    <>
      {/*Session Requested*/}
      {( session.Status === 1) && (
        <Stack width={"100%"}>
          <OikosButton
            buttonText={"Accept"}
            width={"100%"}
            onClick={() => {
              oikos.sessionAccept(session.ID)
                .then(() => {
                  toast({
                    title: "Session accepted",
                    description: "Session accepted successfully.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                    position: "top-right",
                  })
                  closeDrawer();
                })
                .catch((err) => {
                  toast({
                    title: "Error accepting the session",
                    description: "There was an error accepting the session. Please try again later.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                    position: "top-right",
                  })
                })
            }}
          />
          <OikosButton
            buttonText={"Decline"}
            variant={"outline"}
            onClick={() => {
              oikos.sessionDecline(session.ID)
                .then(() => {
                  toast({
                    title: "Session declined",
                    description: "Session declined successfully.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                    position: "top-right",
                  })
                  closeDrawer();
                })
                .catch((err) => {
                  toast({
                    title: "Error declining the session",
                    description: "There was an error declining your session. Please try again later.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                    position: "top-right",
                  })
                })
            }}
          />
        </Stack>)
      }
      {/*Session Accepted*/}
      {( session.Status === 2) && (
        <Stack width={"100%"}>
          <OikosButton
            buttonText={"Start Session"}
            width={"100%"}
            onClick={() => {
              window.open(`https://stoa.polis.ac/room/${roomName}/${oikosUser.ID}`, '_blank')
            }}
          />
          <OikosButton
            buttonText={"Decline Session"}
            variant={"outline"}
            width={"100%"}
            onClick={() => {
              oikos.sessionCancelByEducator(session.ID)
                .then(() => {
                  toast({
                    title: "Session cancelled",
                    description: "Session cancelled successfully.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                    position: "top-right",
                  })
                  closeDrawer();
                })
                .catch((err) => {
                  toast({
                    title: "Error cancelling the session",
                    description: "There was an error cancelling the session. Please try again later.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                    position: "top-right",
                  })
                })
            }}
          />
        </Stack>
      )}
    </>
  )

}

export default SessionsButtonsEducator;
