import React from "react";
import {useNavigate} from "react-router-dom";
import {Avatar, Box, Center, GridItem, Stack, Text} from "@chakra-ui/react";

type CardProps = {
  educator: OIKOS.user;
};

const EducatorCard: React.FC<CardProps> = ({ educator }) => {
  const navigate = useNavigate();
  const halfHourPrice = educator.EducatorProfile.Price.Amount / 200;

  return (
    <GridItem
      colSpan={3}
      h={"400px"}
      bg={"neutral-100"}
      rounded={20}
      shadow={"lg"}
      _hover={{
        bg: "neutral-300",
        cursor: "pointer",
      }}
      onClick={() => {
        console.log(educator);
        navigate(`/educator/${educator.ID}`);
      }}
    >
      <Stack height={"100%"}>
        <Center p={"15px"} bg={"neutral-300"} roundedTop={20}>
          <Avatar
            w={"160px"}
            h={"160px"}
            src={`data:image;base64,${educator.Avatar}`}
          />
        </Center>
        <Box width={"100%"} height={"100%"} p={"12px"} position={"relative"}>
          <Stack>
            <Text fontWeight={"bold"} fontSize={24} color={"neutral-800"}>
              {educator.DisplayName}
            </Text>
          </Stack>
          <Text
            noOfLines={4}
            marginRight={"12px"}
            position={"absolute"}
            bottom={"55px"}
            fontWeight={"light"}
            fontSize={14}
            color={"neutral-600"}
          >
            {educator.Profile.Bio} ...
          </Text>
          {/*<Text*/}
          {/*  position={"absolute"}*/}
          {/*  bottom={"15px"}*/}
          {/*  fontWeight={"bold"}*/}
          {/*  fontSize={18}*/}
          {/*  color={"primary-color"}*/}
          {/*>*/}
          {/*  ${halfHourPrice} / 30 min.*/}
          {/*</Text>*/}
        </Box>
      </Stack>
    </GridItem>
  );
};

export default EducatorCard;