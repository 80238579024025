import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Flex,
  Image,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  IconButton,
  useBreakpointValue,
  useColorModeValue
} from "@chakra-ui/react";
import { FiMenu } from 'react-icons/fi'
import { AvatarMenu } from "../../Components/AvatarMenu";
import {useAuth} from "../../Hooks/useAuth";


const Navbar: React.FC = () => {

  const navigate = useNavigate();
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const {isAuthenticated, isEducator} = useAuth();

  return (
    <>
      <Box as="nav" role="navigation">
        <Box as="section" pb={{ base: '0', md: '0' }} >
          <Box as="nav" bg="bg-surface" boxShadow={useColorModeValue('sm', 'sm-dark')}>
            <Container py={{ base: '4', lg: '5' }} fontFamily={"lato"} color={"neutral-600"} bg={"neutral-100"} maxW={"full"} w={1200}>
              <HStack spacing="0" justify="space-between" maxW={"100%"}>
                {/*<Logo p={"0"} alignItems={"start"}/>*/}
                 <Image
                   cursor={"pointer"}
                   src={"/polis-logo-websitenavbar.svg"}
                   onClick={() => {navigate("/search")}}
                 />
                {isDesktop  ? (
                    isAuthenticated ? (
                  <Flex direction={"row"} color={"neutral-800"} gap={6} flex="1" justify={"end"} alignItems={"center"} w={"full"}>
                    <Link
                      to={"/my-dashboard"}
                    >
                      My Dashboard
                    </Link>
                    <Link
                      to={"/virtual-assistant"}
                    >
                      Virtual Advisor
                    </Link>
                    <Link
                      to={"/inbox"}
                    >
                      Inbox
                    </Link>
                    <Link
                      to={"/sessions"}
                    >
                      Sessions
                    </Link>
                    {/*<Link*/}
                    {/*  to={"/settings"}*/}
                    {/*>*/}
                    {/*  Settings*/}
                    {/*</Link>*/}
                    {/*<Link*/}
                    {/*  to={"/profile"}*/}
                    {/*>*/}
                    {/*  Profile*/}
                    {/*</Link>*/}
                    { !isEducator() &&
                      <Link
                        to={"/search"}
                      >
                        Find Advisors
                      </Link>
                    }
                    { isEducator() &&
                      <Link
                        to={"/schedule"}
                      >
                        Schedule
                      </Link>
                    }
                      <AvatarMenu/>
                  </Flex> ) : (<></>)
                ) : (
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      variant="ghost"
                      icon={<FiMenu fontSize="1.25rem" />}
                      aria-label="Open Menu"
                    />
                    <MenuList minWidth='240px'>
                      <MenuItemOption
                        onClick={() => {navigate("/profile")}}
                        value='asc'>Profile
                      </MenuItemOption>
                      { !isEducator() &&
                      <MenuItemOption
                        onClick={() => {navigate("/search")}}
                        value='asc'>Search
                      </MenuItemOption>
                      }
                      <MenuItemOption
                        onClick={() => {navigate("/sessions")}}
                        value='asc'>Sessions
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {navigate("/settings")}}
                        value='asc'>Settings
                      </MenuItemOption>
                    </MenuList>
                  </Menu>
                )}
              </HStack>
            </Container>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
