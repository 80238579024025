import {listOfLanguages, listOfOfficeHours, listOfDisciplines} from "../oikosConfig";

export const displayPronouns = (pronoun: string) => {
  let convertedPronoun;

  switch (pronoun.toLowerCase()) {
    case "he":
      convertedPronoun = "He/His";
      break;
    case "she":
      convertedPronoun = "She/Her";
      break;
    case "they":
      convertedPronoun = "They/Their";
      break;
    default:
      convertedPronoun = pronoun; // no conversion needed
      break;
  }

  return convertedPronoun;
}

export const splitBioIntoParagraphs = (bio: string) => {
  let paragraphs = bio.split(/\n\n/);
  return paragraphs
}


export const sessionStatusToString = (sessionStatus: OIKOS.SessionStatus) => {
  switch (sessionStatus) {
    case 0:
      return "NEW";
    case 1:
      return "REQUESTED";
    case 2:
      return "ACCEPTED";
    case 3:
      return "DECLINED";
    case 4:
      return "RESCHEDULED";
    case 5:
      return "CANCELLED";
    default:
      return "Unknown";
  }
}

export const getOfficeHourLabel = (key: string) => {
  let res = listOfOfficeHours.find(officeHour => officeHour.key === key);
  if (!res) {
    return ""
  } else {
    return res["label"]
  }
}

export const getLanguageLabel = (key: string) => {
  let res = listOfLanguages.find(lang => lang.key === key);
  if (!res) {
    return ""
  } else {
    return res["label"]
  }
}

export const getLabel = (key: string) => {
  const labels = [...listOfLanguages, ...listOfOfficeHours, ...listOfDisciplines]
  let res = labels.find(labels => labels.key === key);
  if (!res) {
    return ""
  } else {
    return res["label"]
  }
}
