/// <reference path="../SDK/typings.d.ts" />
import React, { useEffect } from "react";
import { Page } from "../OikosDesignSystem/Layout/Page";
import { useOikos } from "../SDK/OikosContext";
import { useAuth } from "../Hooks/useAuth";
import {
  Stack,
  Flex,
  useToast,
} from "@chakra-ui/react";
import OikosSideDecoration from "../OikosDesignSystem/OikosSideDecoration";
import SessionCard from "../Components/SessionCard";


const Sessions:React.FC = () => {
  const [sessions, setSessions] = React.useState<OIKOS.Session[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const toast = useToast();
  const { oikos } = useOikos();
  const {oikosUser } = useAuth();

  const refresh = () => {
    setLoaded(false)
  }

  useEffect(() => {
    if (!loaded) {
      oikos.getUserSessions(oikosUser.ID)
        .then((sessions) => {
          console.log(sessions)
          setSessions(sessions);
          setLoaded(true);
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Error loading sessions",
            description: "There was an error loading your sessions. Please try again later.",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        })
    }
  }, [loaded, oikosUser.ID, oikos, toast]);


  return (
    <Page title={"My Sessions"} backgroundImage={"SearchBackground.svg"}>
      <OikosSideDecoration/>
      <Flex zIndex={1}>
        <Stack spacing={"10px"} width={"100%"}>
        {sessions.map((ses) => {
            return <SessionCard session={ses} refresh={refresh} />
          })}
        </Stack>
      </Flex>
    </Page>
  )
}


export default Sessions;
