import React from 'react';
import { Button } from '@chakra-ui/react';

type Props = {
  buttonText: string
  [rest:string]: any;
}

const OikosButton: React.FC<Props>= ({buttonText, onClick, ...rest}) => {

  const defaultScheme = {
    bg: "primary-color-1",
    color: "neutral-100",
    _hover: {
      background: "neutral-500",
      color: "neutral-100",
    }
  }

  const outlineScheme = {
    bg: "neutral-100",
    color: "primary-color-1",
    _hover: {
      background: "primary-color-1",
      color: "neutral-100",
    },
    borderColor: "primary-color-1",
    borderWidth: 1
  }

  const yellowScheme = {
    bg: "secondary-color-5",
    color: "neutral-800",
    _hover: {
      background: "primary-color-1",
      color: "neutral-100",
    },
  }

  const disabledScheme = {
    bg: "neutral-400",
    color: "neutral-100",
    _hover: {
      background: "neutral-200",
      color: "neutral-400",
    },
  }

  let schema = defaultScheme;
  if (rest.variant === "outline") {
    schema = outlineScheme;
  }
  if (rest.variant === "yellow") {
    schema = yellowScheme;
  }
  if (rest.variant === "disabled") {
    schema = disabledScheme;
  }

  return (
    <Button
      onClick={onClick}
      borderRadius="10"
      h={"64px"}
      w={"100%"}
      fontFamily={"lato"}
      fontWeight={"bold"}
      fontSize={20}
      {...schema}
      {...rest}
    >
      {buttonText}
    </Button>
  );
};

export default OikosButton;
