import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Img,
  Stack,
  Text,
  Tab, TabList, TabIndicator, TabPanels, TabPanel, Tabs,
  useBreakpointValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { Logo } from '../Logo'
import {useAuth} from "../Hooks/useAuth";
import {useNavigate} from "react-router-dom";

const Login = () => {

  const [password, setPassword] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [familyName, setFamilyName] = React.useState<string>("");

  const navigate = useNavigate();
  const { signIn, signUp } = useAuth();


  return (
    <Flex maxW="100%" py={{base: '12', md: '24'}} justifyContent={"center"}>
      <Box w={640} pb={"80px"} bg={"neutral-100"} rounded={20} shadow={"md"}>

        <Tabs align={"center"} pt={63} variant={"unstyled"}>

          <TabList>
            <Tab w={200}>
              <Stack>
                <Img
                  src={"assets/LockSquare.svg"}
                />
                <Text pt={10} fontFamily={"lato"} color={"neutral-800"} fontWeight={"bold"} fontSize={"38"}>
                  Log In
                </Text>
              </Stack>
            </Tab>
            <Tab w={200}>
              <Stack>
                <Img
                  src={"assets/PenSquare.svg"}
                />
                <Text pt={10} fontFamily={"lato"} color={"neutral-800"} fontWeight={"bold"} fontSize={"38"}>
                  Sign Up
                </Text>
              </Stack>
            </Tab>
          </TabList>

          <TabIndicator
            mt="10px"
            width={140}
            height="5px"
            bg="secondary-color-5"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <Stack spacing="5" width={470}>
                <FormControl pt={10}>
                  <Input
                    id="email"
                    h={65}
                    placeholder="Email Address"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl pb={10}>
                  <Input
                    h={65}
                    id="password"
                    placeholder="Password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>

                <Button
                  variant="primary"
                  bg={"primary-color"}
                  color={"neutral-100"}
                  fontFamily={"lato"}
                  fontWeight={"bold"}
                  fontSize={"18"}
                  h={65}
                  onClick={() => {
                    signIn(email, password).then(() => {
                      navigate("/");
                    });
                  }}
                >
                  Log In
                </Button>
                <Text
                  color="primary-color"
                  _hover={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => navigate('/passwordreset')}
                >
                  Forgot Password?
                </Text>
              </Stack>
            </TabPanel>

            <TabPanel>
              <Tabs align={"center"} pt={63} variant={"soft-rounded"}>
                <TabList>
                  <Tab
                    w={"180px"}
                    _selected={{
                      borderColor: "primary-color",
                      borderWidth: "2px"
                    }}
                    rounded={20}
                    bg={"neutral-200"}
                    shadow={"md"}
                    mr={"50px"}
                  >
                    <Stack align={"center"} py={"33px"} spacing={"12px"}>
                      <Img
                        src={"assets/learner.svg"}
                        w={"90px"}
                        h={"90px"}
                      />
                      <Text fontFamily={"lato"} color={"neutral-800"} fontWeight={"bold"} fontSize={"18"}>
                        I'm a learner
                      </Text>
                      <Box w={"100px"} h={"4px"} bg={"primary-color"}/>
                    </Stack>
                  </Tab>
                  <Tab
                    w={"180px"}
                    _selected={{
                      borderColor: "secondary-color-6",
                      borderWidth: "2px"
                    }}
                    rounded={20}
                    bg={"neutral-200"}
                    shadow={"md"}
                  >
                    <Stack align={"center"} py={"33px"} spacing={"12px"}>
                      <Img
                        src={"assets/educator.svg"}
                        w={"90px"}
                        h={"90px"}
                      />
                      <Text fontFamily={"lato"} color={"neutral-800"} fontWeight={"bold"} fontSize={"18"}>
                        I'm an educator
                      </Text>
                      <Box w={"100px"} h={"4px"} bg={"secondary-color-6"}/>
                    </Stack>
                  </Tab>
                </TabList>

                {/*<TabIndicator*/}
                {/*  mt="10px"*/}
                {/*  width={140}*/}
                {/*  height="5px"*/}
                {/*  bg="secondary-color-5"*/}
                {/*  borderRadius="1px"*/}
                {/*/>*/}
                <TabPanels>
                  <TabPanel>
                    <Stack spacing="5" width={470}>
                      <FormControl pt={10}>
                        <Input
                          id="email"
                          h={65}
                          placeholder="Email Address"
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <Input
                          id="name"
                          h={65}
                          placeholder="Name"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </FormControl>
                      <FormControl pb={10}>
                        <Input
                          id="familyName"
                          h={65}
                          placeholder="Family Name"
                          onChange={(e) => setFamilyName(e.target.value)}
                        />
                      </FormControl>

                      <Button
                        variant="primary"
                        bg={"primary-color"}
                        color={"neutral-100"}
                        fontFamily={"lato"}
                        fontWeight={"bold"}
                        fontSize={"18"}
                        h={65}
                        onClick={() => {
                          signUp(email, name, familyName,"B2C_LEARNER")
                            .then((success: boolean) => {
                              if (success) {
                                navigate("/signup/success");
                              }
                            });
                        }}
                      >
                        Sign Up as Learner
                      </Button>
                    </Stack>
                  </TabPanel>
                  <TabPanel>
                    <Stack spacing="5" width={470}>
                      <FormControl pt={10}>
                        <Input
                          id="email"
                          h={65}
                          placeholder="Email Address"
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <Input
                          id="name"
                          h={65}
                          placeholder="Name"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </FormControl>
                      <FormControl pb={10}>
                        <Input
                          id="familyName"
                          h={65}
                          placeholder="Family Name"
                          onChange={(e) => setFamilyName(e.target.value)}
                        />
                      </FormControl>

                      <Button
                        variant="primary"
                        bg={"primary-color"}
                        color={"neutral-100"}
                        fontFamily={"lato"}
                        fontWeight={"bold"}
                        fontSize={"18"}
                        h={65}
                        onClick={() => {
                          signUp(email, name, familyName,"EDUCATOR")
                            .then((success: boolean) => {
                              if (success) {
                                navigate("/signup/success");
                              }
                            });
                        }}
                      >
                        Sign Up as Educator
                      </Button>
                    </Stack>
                  </TabPanel>
                </TabPanels>
              </Tabs>


            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

    </Flex>
  )
}


export default Login;
