import React from "react";
import { Text } from "@chakra-ui/react";


export const SectionTitle = (props: any) => {
  return (
    <Text
      className={"uppercase"}
      fontSize={16}
      fontFamily={ "literata"}
      fontWeight="bold"
      // textAlign="left"
      color="neutral-800"
      {...props}
    >
      {props.children}
    </Text>
  );
}


export const SectionSubtitle = (props: any) => {
  return (
    <Text
      fontSize={38}
      fontFamily={ "lato"}
      fontWeight="bold"
      // textAlign="left"
      color="neutral-800"
      {...props}
    >
      {props.children}
    </Text>
  );
}

export const SectionBody = (props: any) => {
  return (
    <Text
      fontSize={18}
      fontFamily={ "lato"}
      fontWeight="normal"
      // textAlign="left"
      color="neutral-800"
      {...props}
    >
      {props.children}
    </Text>
  );
}


export const PageTitle = (props: any) => {
  return (
    <Text
      fontSize={54}
      fontFamily={ "lato"}
      fontWeight="bold"
      // textAlign="left"
      color="neutral-800"
      {...props}
    >
      {props.children}
    </Text>
  );
}


export const Title = (props: any) => {
  return (
    <Text
      fontSize={38}
      fontFamily={ "lato"}
      fontWeight="bold"
      // textAlign="left"
      color="neutral-800"
      {...props}
    >
      {props.children}
    </Text>
  );
}
