import {Box, Stack, Text, HStack, Flex} from "@chakra-ui/react";
import React from "react";
import OikosButton from "../../OikosDesignSystem/OikosButton";
import TimeSlotSelector from "../TimeSlotSelector";
import OikosRadioButton from "../../OikosDesignSystem/OikosRadio";
import {useAuth} from "../../Hooks/useAuth";



type Props = {
  educator: OIKOS.user;
  preview?: boolean;
}



const BookSessionForm: React.FC<Props> = ({educator, preview = false}) => {

  const halfHourPrice = educator.EducatorProfile.Price.Amount / 200;
  const [duration, setDuration] = React.useState<number>(30);

  const { user } = useAuth();




  return (
    <Flex
      // height={"626px"}
      width={"520px"}
      rounded={"20px"}
      background={"neutral-100"}
      shadow={"lg"}
    >
      <Stack p={"38px"}>
        <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={32} color={"neutral-800"}>
          Book a Session with {educator.Name}
        </Text>
        <Text pt={"16px"} fontFamily={"lato"} fontWeight={"medium"} fontSize={18} color={"neutral-600"}>
          Minimum Notice:
        </Text>
        {/*<HStack>*/}
        {/*  <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={18} color={"neutral-600"}>*/}
        {/*    Price:*/}
        {/*  </Text>*/}
        {/*  <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={18} color={"neutral-800"}>*/}
        {/*    ${halfHourPrice} / 30 minutes*/}
        {/*  </Text>*/}
        {/*</HStack>*/}
        <HStack pb={"38px"}>
          <Text fontFamily={"lato"} fontWeight={"medium"} fontSize={18} color={"neutral-600"}>
            TimeZone:
          </Text>
          <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={18} color={"neutral-800"}>
            {educator.Profile.Timezone}
          </Text>
        </HStack>

        <Box background={"neutral-400"} width={"380px"} height={"2px"} />
        <Text pt={"38px"} fontFamily={"lato"} fontWeight={"bold"} fontSize={24} color={"neutral-800"}>
          Pick the session length
        </Text>

        <OikosRadioButton
          options={["30 minutes", "1 hour"]}
          value={"30 minutes"}
          onChange={(val) => {
            if (val === "30 minutes") {
              setDuration(30);
            } else {
              setDuration(60);
            }
          }}
        />

        <TimeSlotSelector
          onTimeSlotChange={() => {console.log("click")}}
          duration={duration}
          educator={educator}
          learnerID={user.ID}
          preview={preview}
        />

        {/*<Text align={"center"} fontFamily={"lato"} fontWeight={"bold"} fontSize={14} color={"primary-color-1"}>*/}
        {/*  You won´t be charged yet*/}
        {/*</Text>*/}
        {!preview && <OikosButton
          buttonText={"Request to Book"}
        />
        }

      </Stack>


    </Flex>
  )
}



export default BookSessionForm;
