import {Page} from "../OikosDesignSystem/Layout/Page";
import {useAuth} from "../Hooks/useAuth";
import LearnerProfile from "../Profile/LearnerProfile";
import EducatorProfile from "../Profile/EducatorProfile";
import OikosSideDecoration from "../OikosDesignSystem/OikosSideDecoration";
import React from "react";
import {Flex} from "@chakra-ui/react";






export const Profile = () => {

  const {isEducator} = useAuth();


  return (
    <Page title={"Your Profile"}>
      <OikosSideDecoration/>
      <Flex zIndex={1}>
      {isEducator() ? (
        <EducatorProfile />
      ) : (
        <LearnerProfile />
      )}
      </Flex>
    </Page>
  );
}

export default Profile;
