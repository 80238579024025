import React, {useEffect} from "react";
import {
  Avatar,
  Box,
  Container,
  Drawer, DrawerBody,
  DrawerContent, DrawerFooter, DrawerHeader,
  Grid,
  GridItem, HStack,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {useAuth} from "../Hooks/useAuth";
import {useOikos} from "../SDK/OikosContext";
import moment from "moment/moment";
import {UserProfileBio, UserProfileHeader} from "./UserProfile";
import SessionsButtonsEducator from "../Sessions/SessionsButtonsEducator";
import SessionsButtonsLearner from "../Sessions/SessionsButtonsLearner";
import ConversationApp from "../Conversations/ConversationApp";

const SessionCard: React.FC<{session: OIKOS.Session, refresh: () => void}> = ({session, refresh}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {isEducator, oikosUser} = useAuth();
  const {oikos} = useOikos();

  const [counterpart, setCounterpart] = React.useState<OIKOS.user>();
  const [conversation, setConversation] = React.useState<OIKOS.Conversation>();
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const date = moment(session.StartTime)
  const dateString = date.format("dddd, MMMM Do YYYY")



  const closeDrawer = () => {
    onClose();
    refresh();
  }

  useEffect(() => {
    if (!loaded) {
      let counterpartType = session.Educator;
      if (isEducator()) {
        counterpartType = session.Learner
      }
      oikos.getUser(counterpartType)
        .then((ctpart) => {
          setCounterpart(ctpart);
          setLoaded(true);
        })
        .catch((err) => {
          console.error(err);
        })
    }
  }, [loaded, session.Educator, session.Learner, isEducator, oikos]);





  useEffect(() => {
    if (!loaded) {
      const uniqueName = session.Educator + session.Learner;
      oikos.getUserConversations(oikosUser.ID)
        .then((convos: OIKOS.Conversation[]) => {
          for (let i = 0; i < convos.length; i++) {
            if (convos[i].UniqueName === uniqueName) {
              setConversation(convos[i]);
              break;
            }
          }
          setLoaded(true);
        })
        .catch((err) => {
          console.log("This is a new conversation");
        })
    }
  }, []);


  const sessionStatusString = (value: OIKOS.SessionStatus) => {
    switch (value) {
      case 1: //OIKOS.SessionStatus.REQUESTED:
        return <Text fontWeight={"bold"} align="right" color={"primary-color"}>Requested</Text>
      case 2: //OIKOS.SessionStatus.ACCEPTED:
        return <Text fontWeight={"bold"} align="right" color={"green"}>Accepted</Text>
      case 3: //OIKOS.SessionStatus.DECLINED:
        return <Text fontWeight={"bold"} align="right" color={"red"}>Declined</Text>
      case 4: //OIKOS.SessionStatus.RESCHEDULED:
        return <Text fontWeight={"bold"} align="right"  color={"orange"}>Rescheduled</Text>
      case 5: //OIKOS.SessionStatus.CANCELLED:
        return <Text fontWeight={"bold"} align="right" color={"orange"}>Cancelled</Text>
    }
    return <></>
  }

  let background = "neutral-100";
  if (session.Status !== 1 && session.Status !== 2) {
    background = "neutral-400";
  }

  return (
    <>
      {loaded && <Box
        bg={background}
        rounded={20}
        boxShadow={"lg"}
        onClick={onOpen}
        _hover={{
          cursor: "pointer",
          bg: "neutral-300"
        }}
      >
        <Container>
          <Grid templateColumns={"repeat(12,1fr)"} w={"100%"} p={"15px"} >
            <GridItem colSpan={1}>
              <Avatar
                src={`data:image;base64,${counterpart?.Avatar}`}
                size={"lg"}
              />
            </GridItem>
            <GridItem colSpan={8}>
              <Stack>
                <Text fontWeight={"bold"}>{counterpart?.DisplayName}</Text>
                {/*<Text>{counterpart?.Profile.Title}</Text>*/}
                <Text fontWeight={"bold"} color={"primary-color"}>{dateString }</Text>
              </Stack>
            </GridItem>
            <GridItem colSpan={3}>
              {sessionStatusString(session.Status)}
            </GridItem>
          </Grid>
        </Container>
      </Box> }
      <Drawer
        colorScheme={"blackAlpha"}
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={'xl'}
        // size={['full', 'full', 'lg', 'lg', 'lg', 'lg']}
      >
        <DrawerContent scrollBehavior={"smooth"} sx={{ width: '70vw' }}>

          <DrawerHeader>
            <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={32} color={"neutral-800"}>
              Session with {counterpart?.Name}
            </Text>
          </DrawerHeader>
          <DrawerBody >
            <HStack height={"100%"}>
              <Stack flex={1} p={"38px"} spacing={8} height={"100%"} >
                <HStack>
                  <Avatar
                    src={`data:image;base64,${counterpart?.Avatar}`}
                    w={"125px"}
                    h={"125px"}
                  />
                  <UserProfileHeader oikosUser={counterpart!}/>
                </HStack>
                <UserProfileBio oikosUser={counterpart!}/>
                {isEducator() ? (
                  <SessionsButtonsEducator
                    session={session}
                    closeDrawer={closeDrawer}
                  />
                ) : (
                  <SessionsButtonsLearner
                    session={session}
                    closeDrawer={closeDrawer}
                  />
                )}
              </Stack>

              <Stack flex={1}>
                {conversation && counterpart && <ConversationApp conversation={conversation} oikosUser={oikosUser} counterpartID={counterpart.ID} /> }
              </Stack>
            </HStack>
          </DrawerBody>
          <DrawerFooter>


          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default SessionCard;
