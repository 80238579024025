import AvatarImage from "../AvatarImage";
import {Flex, Text, Grid, GridItem, Stack, Box} from "@chakra-ui/react";
import React from "react";
import ProfileAvatar from "../../Profile/ProfileAvatar";
import {useAuth} from "../../Hooks/useAuth";
import ProfileContentBox from "../../Profile/ProfileContentBox";
import {Title} from "../../OikosDesignSystem/Typography";

export const BannerUserProfile = () => {

  const {oikosUser} = useAuth();


  const bannerStudent = {
      "citizenshipStatus": {
        "category": "citizen",
        "detail": {
          "id": "7798735e-5f82-4f01-9b0d-468514cddbc2"
        }
      },
      "credentials": [
        {
          "type": "bannerSourcedId",
          "value": "4472"
        },
        {
          "type": "bannerUserName",
          "value": "adamsje"
        },
        {
          "type": "bannerUdcId",
          "value": "1620CFE4A2C67F4CE05310EC3F0A36C2"
        },
        {
          "type": "bannerId",
          "value": "R00007201"
        }
      ],
      "dateOfBirth": "1952-06-09",
      "emails": [
        {
          "address": "adamsje@roanestate.edu",
          "preference": "primary",
          "type": {
            "detail": {
              "id": "28f0a535-ac97-48cf-97ff-98976dd26f17"
            },
            "emailType": "billing"
          }
        }
      ],
      "ethnicity": {
        "ethnicGroup": {
          "id": "46cca1ba-13d0-48a1-a6b2-66ebd241da2d"
        },
        "reporting": [
          {
            "country": {
              "code": "USA",
              "ethnicCategory": "nonHispanic"
            }
          }
        ]
      },
      "gender": "female",
      "genderMarker": {
        "id": "1f52e2fe-d993-44e5-bf90-e5f053649b05"
      },
      "id": "c8ecec33-5c7e-4631-b3c4-3ea47c00bace",
      "names": [
        {
          "firstName": "Joy",
          "fullName": "Joy Elizabeth Adams",
          "lastName": "Adams",
          "middleName": "Elizabeth",
          "preference": "preferred",
          "title": "Ms",
          "type": {
            "category": "birth",
            "detail": {
              "id": "7890ea26-6a10-43ba-a533-6315050f873b"
            }
          }
        }
      ],
      "privacyStatus": {
        "privacyCategory": "unrestricted"
      },
      "races": [
        {
          "race": {
            "id": "a791f5ab-5e1b-41d9-996a-30d1c8513b83"
          }
        }
      ],
      "roles": [
        {
          "endOn": "1998-05-15T00:00:00Z",
          "role": "student",
          "startOn": "1995-01-10T00:00:00Z"
        },
        {
          "role": "alumni",
          "startOn": "2008-11-13T00:00:00Z"
        }
      ]
    }



  const bannerUser = {
    DisplayName: "John Doe",
    StudentID: "R00000000",
    Email: "doej@roanestate.edu",
    Phone: "555-555-5555",
    Address: "123 Main St. Anytown, USA 12345",
    Major: "Criminal Justice",
  }

  return (
    <Grid templateColumns='repeat(12, 1fr)'>
      <GridItem colSpan={9} position={"relative"}>
        <ProfileAvatar
          user={oikosUser}
        />
        <Stack spacing={"24px"} pt={"70px"}>
          <ProfileContentBox
            title={"Personal Information"}
          >
            <Flex direction={"row"}>
              <AvatarImage />
              <Flex direction={"column"}>
                <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={16} color={"neutral-800"}>
                  {bannerUser.DisplayName}
                </Text>
                <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={16} color={"neutral-800"}>
                  {bannerUser.StudentID}
                </Text>
                <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={16} color={"neutral-800"}>
                  {bannerUser.Email}
                </Text>
                <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={16} color={"neutral-800"}>
                  {bannerUser.Phone}
                </Text>
                <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={16} color={"neutral-800"}>
                  {bannerUser.Address}
                </Text>
              </Flex>
              <Flex direction={"column"}>
                <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={16} color={"neutral-800"}>
                  {bannerUser.Major}
                </Text>
              </Flex>
            </Flex>
          </ProfileContentBox>
          <ProfileContentBox
            title={"Banner Info"}
          >
          </ProfileContentBox>
          <ProfileContentBox
            title={"Coach's Log"}
          >
          </ProfileContentBox>
        </Stack>
      </GridItem>
      <GridItem colSpan={3} position={"relative"} pt={"70px"} pl={"24px"}>
        <Box
          bg={"neutral-100"}
          rounded={20}
          shadow={"md"}
        >
          <Box p={"20px"}>
            <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={20} color={"neutral-800"}>Alerts</Text>
            <Stack pt={"25px"}>
              <Box bg={"red.200"} rounded={10}>
                <Text p={3} fontWeight={"bold"} >June 1st, 2024</Text>
                <Text p={3} fontSize={14}>Register for Summer Semester</Text>
              </Box>
              <Box bg={"red.200"} rounded={10}>
                <Text p={3} fontWeight={"bold"} >June 1st, 2024</Text>
                <Text p={3} fontSize={14}>Some other alert</Text>
              </Box>
            </Stack>

          </Box>
        </Box>
      </GridItem>

    </Grid>

  )

}
