import React from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Text,
  useDisclosure,
  Input,
  Stack, HStack
} from "@chakra-ui/react";
import OikosButton from "../OikosDesignSystem/OikosButton";
import {useOikos} from "../SDK/OikosContext";





type Props = {
  UserID: string
  DayOfWeek: number
  refresh: () => void
}

const AddAvailabilityBlockDrawer: React.FC<Props> = ({UserID, DayOfWeek, refresh}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const [startTime, setStartTime] = React.useState<string>("")
  const [endTime, setEndTime] = React.useState<string>("")

  const {oikos} = useOikos()

  const handleAddAvailabilityBlock = () => {
    oikos.addAvailabilityBlock(UserID, DayOfWeek, startTime, endTime).then(() => {
      console.log("Block Added")
      refresh()
    })
  }

  return (
    <>
      <Button
        // buttonText={"Add Availability Block"}
        colorScheme={"primary-color"}
        onClick={onOpen}
      >Add Block</Button>
      <Drawer
        isOpen={isOpen}
        placement={'right'}
        onClose={onClose}
        size={"sm"}
      >
        <DrawerContent scrollBehavior={"smooth"}>
          <DrawerHeader>
            <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={24} color={"neutral-800"}>
              Add Availability Block
            </Text>
          </DrawerHeader>
          <DrawerBody>

            <Stack spacing={8}>


              <Text fontFamily={"lato"}>Start Time</Text>
                <Input
                  size="md"
                  type="time"
                  onChange={(e) => {setStartTime(e.target.value)}}
                />
              <Box height={"1px"} width={"100%"} bg={"neutral-600"}/>
              <Text fontFamily={"lato"}>End Time</Text>
                <Input
                  size="md"
                  type="time"
                  onChange={(e) => {setEndTime(e.target.value)}}
                />
              <Box height={"1px"} width={"100%"} bg={"neutral-600"}/>
            </Stack>


          </DrawerBody>
          <DrawerFooter>
            <OikosButton
              buttonText={"Add Availability Block"}
              onClick={handleAddAvailabilityBlock}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

    </>
  )
}


export default AddAvailabilityBlockDrawer;
