import {
  Textarea, Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

type Props = {
  label: string;
  onChange?: (e: any) => void;
  value?: string;
  placeholder?: string;
}

export const FormTextArea = (props: any) => {

  const [value, setValue] = React.useState(props.value)

  const onChange = (e: any) => {
    setValue(e.target.value)
    props.onChange(e.target.value)
  }

  return (
    <Stack spacing={"8px"}>
      <Text fontFamily={"lato"} fontSize={18} fontWeight={"bold"} color={"neutral-800"} >
        {props.label}
      </Text>
      <Textarea
        h={"146px"}
        w={"100%"}
        value={value}
        placeholder={props.placeholder}
        onChange={onChange}
      />
    </Stack>
  )

}
