import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import { ChakraProvider } from "@chakra-ui/react";
import { ProvideAuth } from "./Hooks/useAuth";
import { extendTheme } from "@chakra-ui/react"
import { theme as proTheme } from '@chakra-ui/pro-theme'
import "@fontsource/lato"
import "@fontsource/literata"


export const theme = extendTheme(
  {
    fonts: {
      heading: `'Lato', sans-serif`,
      body: `'Literata', serif`,
    },
    colors: {
      "primary-color": "#142999",
      "primary-color-1": "#142999",
      "secondary-color-1": "#142999",
      "secondary-color-2": "#F8F8FF",
      "secondary-color-3": "#3022CD",
      "secondary-color-4": "#FF4A4A",
      "secondary-color-5": "#FFD600",
      "secondary-color-6": "#FF6262",
      "neutral-100" : "#FFFFFF",
      "neutral-200" : "#F8FBFF",
      "neutral-300" : "#EFF0F6",
      "neutral-400" : "#DCDDEB",
      "neutral-500" : "#BCBED1",
      "neutral-600" : "#8184A3",
      "neutral-800" : "#14142B"
    },
    styles: {
      global: {
        "::-webkit-scrollbar": {
          width: "8px", // Set the width of the scrollbar
        },
        "::-webkit-scrollbar-track": {
          background: "gray.100", // Set the background color of the track
        },
        "::-webkit-scrollbar-thumb": {
          background: "gray.300", // Set the color of the thumb
          borderRadius: "full", // Round the corners of the thumb
        },
      },
    },
  },
  proTheme,
)


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
  {/*<React.StrictMode>*/}
    <ChakraProvider theme={theme}>
      <ProvideAuth>
        <App />
      </ProvideAuth>
    </ChakraProvider>
  {/*</React.StrictMode>*/}
  </>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

