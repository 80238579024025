import React from 'react'
import {
  Flex,
  SimpleGrid
} from "@chakra-ui/react";

type Props = {
  children: React.ReactNode
}

const TwoColumnsGrid: React.FC<Props> = ({ children }) => {
  return (
    <Flex justifyContent={"space-between"} maxW={1200} width={"100%"}>
      <SimpleGrid
        columns={[1, 1, 1, 2, 2]}
        width={"100%"}
        spacingX={20}
      >
        {children}
      </SimpleGrid>
    </Flex>
  )
}

export default TwoColumnsGrid
