import {Box, Flex, Img} from "@chakra-ui/react";
import {PageTitle} from "../Typography";


type Props = {
  children: React.ReactNode;
  title: string;
  backgroundImage?: string;
}


export const Page: React.FC<Props> = (props) => {

  const background = props.backgroundImage ?
    `linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url(${props.backgroundImage})` :
    "neutral-200";

  return (
    <Flex
      // backgroundImage={`linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url(${props.backgroundImage})`}
      backgroundSize="auto"
      backgroundPosition="center"
      minHeight="100vh"
      width="100%"
      maxW={"1440px"}
      alignItems={"center"}
      justifyContent={"center"}
      background={background}
      position={"relative"}
    >
      <Flex direction="column" pb={"102px"} maxWidth={1200} width={"100%"} height={"100%"}>

        <PageTitle py={"20"}>{props.title}</PageTitle>
          {props.children}
      </Flex>
    </Flex>
  );
}
