import {Page} from "../OikosDesignSystem/Layout/Page";
import {useAuth} from "../Hooks/useAuth";
import OikosSideDecoration from "../OikosDesignSystem/OikosSideDecoration";
import React from "react";
import {Flex} from "@chakra-ui/react";
import {BannerUserProfile} from "../Components/Banner/StudentBlock";






export const HomePageBanner = () => {

  const {isEducator} = useAuth();


  return (
    <Page title={"Home"}>
      <OikosSideDecoration/>
      <Flex zIndex={1}>
        <BannerUserProfile />
      </Flex>
    </Page>
  );
}

export default HomePageBanner;
