import React, {useRef} from "react";
import {Grid, GridItem, Stack, useToast} from "@chakra-ui/react";
import {FormInput} from "../OikosDesignSystem/Components/FormInput";
import {FormSelect} from "../OikosDesignSystem/Components/FormSelect";
import OikosButton from "../OikosDesignSystem/OikosButton";
import {FormTextArea} from "../OikosDesignSystem/Components/FormTextArea";
import ProfileContentBox from "./ProfileContentBox";
import ProfileButtons from "./ProfileButtons";
import {useAuth} from "../Hooks/useAuth";
import momentTZ from "moment-timezone";
import {useOikos} from "../SDK/OikosContext";
import AddCreditCardDrawer from "../Components/AddCreditCardDrawer";
import ProfileAvatar from "./ProfileAvatar";
import ListManager from "../Components/ListManager";
import {listOfLanguages, listOfOfficeHours} from "../oikosConfig";
import EducationManager from "../Components/EducationManager";


const LearnerProfile = () => {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = React.useState<any[]>([]);

  const {oikosUser} = useAuth();

  let updateableUser = oikosUser;
  const {oikos} = useOikos();
  const toast = useToast();

  if (updateableUser.Profile.Timezone === '') {
    updateableUser.Profile.Timezone = momentTZ.tz.guess();
  }

  const timeZonesList = momentTZ.tz.names();

  const learnerProfileRef = useRef(null);
  const educationRef = useRef(null);
  const languagesRef = useRef(null);
  const bioRef = useRef(null);
  // const paymentRef = useRef(null);

  const updateProfile = () => {
    console.log(updateableUser);
    oikos.updateUser(updateableUser)
      .then((res: any) => {
        toast({
          title: "Profile Updated Successfully",
          description: "Your profile has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((err: any) => {
        toast({
          title: "Error Updating Profile",
          description: "There was an error updating your Profile. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      })
  }




  if (!loaded) {
    oikos.getPaymentMethods(oikosUser.ID).then((res: any) => {
      console.log(res)
      let pm: any[] = []
      res.forEach((paymentMethod: any) => {
        pm.push(paymentMethod.card)
      })

      console.log(pm)
      setLoaded(true)
      setPaymentMethods(pm)
    })
  }


  const setProfileButtons = () => {
    return (
      <Stack spacing={"16px"} pt={"134px"}>
        <ProfileButtons
          title={"Personal Information"}
          destinationRef={learnerProfileRef}
        />
        <ProfileButtons
          title={"Education"}
          destinationRef={educationRef}
        />
        <ProfileButtons
          title={"Languages"}
          destinationRef={languagesRef}
        />
        <ProfileButtons
          title={"What I Need Help With"}
          destinationRef={bioRef}
        />
        {/*<ProfileButtons*/}
        {/*  title={"Payment Methods"}*/}
        {/*  destinationRef={paymentRef}*/}
        {/*/>*/}
      </Stack>
    )
  }


  const handleProfilePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      oikos.uploadProfilePicture(oikosUser.ID, file)
        .then((res) => {
          toast({
            title: "Profile Picture Uploaded Successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          // Optionally, refresh the user data to reflect the new picture
        })
        .catch((err) => {
          toast({
            title: "Error Uploading Profile Picture",
            description: "There was an error uploading your profile picture. Please try again later.",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        });
    }
  };


  return (
    <>
      <Grid templateColumns='repeat(12, 1fr)'>
        <GridItem colSpan={3}>
          {setProfileButtons()}
        </GridItem>

        <GridItem colSpan={9} position={"relative"}>
          <ProfileAvatar
            user={updateableUser}
          />
          <Stack spacing={"24px"} pt={"70px"}>
            <ProfileContentBox
              title={"Personal Information"}
              ref={learnerProfileRef}
            >
              <Grid templateColumns="repeat(9, 1fr)">
                <GridItem colSpan={4} h={"130px"}>
                  <FormInput
                    label={"First Name"}
                    value={updateableUser.Name}
                    onChange={(value: any) => {
                      updateableUser.Name = value;
                    }}
                  />
                </GridItem>
                <GridItem colSpan={1}/>
                <GridItem colSpan={4}>
                  <FormInput
                    label={"Last Name"}
                    value={updateableUser.FamilyName}
                    onChange={(value: any) => {
                      updateableUser.FamilyName = value;
                    }}
                  />
                </GridItem>

                <GridItem colSpan={9} h={"130px"}>
                  <FormInput
                    label={"Email"}
                    value={updateableUser.Email}
                    // onChange={(value: any) => {
                    //   updateableUser.FamilyName = value;
                    // }}
                  />
                </GridItem>

                <GridItem colSpan={4} h={"130px"}>
                  <FormSelect
                    label={"Title"}
                    options={[["mr", "Mr."], ["ms", "Ms."], ["mrs", "Mrs."], ["dr", "Dr."]]}
                    value={updateableUser.Profile.Title}
                    onChange={(value: any) => {
                      updateableUser.Profile.Title = value;
                    }}
                  />
                </GridItem>
                <GridItem colSpan={1}/>
                <GridItem colSpan={4}>
                  <FormSelect
                    label={"Preferred Pronouns"}
                    options={[["he", "He/Him"], ["she", "She/Her"], ["they", "They/Them"]]}
                    value={updateableUser.Profile.Pronouns}
                    onChange={(value: any) => {
                      updateableUser.Profile.Pronouns = value;
                    }}
                  />
                </GridItem>

                <GridItem colSpan={9} h={"130px"}>
                  <FormSelect
                    label={"Your Timezone"}
                    options={timeZonesList.map((tz) => [tz, tz])}
                    value={updateableUser.Profile.Timezone}
                    onChange={(value: any) => {
                      updateableUser.Profile.Timezone = value;
                    }}
                  />
                </GridItem>

                <GridItem colSpan={9} h={"202px"}>
                  <FormTextArea
                    label={"About Me"}
                    value={updateableUser.Profile.Bio}
                    onChange={(value: any) => {
                      updateableUser.Profile.Bio = value;
                    }}
                  />
                </GridItem>

                <GridItem pt={"42px"} colSpan={3} h={"130px"}>
                  <OikosButton
                    buttonText={"Save Changes"}
                    onClick={updateProfile}
                  />
                </GridItem>

              </Grid>
            </ProfileContentBox>


            {/* Education and Languages */}
            <ProfileContentBox
              title={"Education"}
              ref={educationRef}
            >
              <Grid templateColumns="repeat(10, 1fr)">
                <GridItem colSpan={10} >
                  <EducationManager
                    user={updateableUser}
                    updateFunction={updateProfile}
                  />
                </GridItem>

              </Grid>
            </ProfileContentBox>

            <ProfileContentBox
              title={"Languages"}
              ref={languagesRef}
            >
              <Grid>
                <GridItem colSpan={9}>
                  <ListManager
                    list={updateableUser.Profile.Languages}
                    presetValues={listOfLanguages}
                    updateItems={(newList: string[]) => {
                      updateableUser.Profile.Languages = newList;
                      updateProfile();
                    }}
                  />
                </GridItem>
              </Grid>
            </ProfileContentBox>

            <ProfileContentBox
              title={"What I Need Help With"}
              ref={bioRef}
            >
              <Grid templateColumns="repeat(9, 1fr)">
                <GridItem colSpan={9}>
                  <ListManager
                    list={updateableUser.LearnerProfile.NeedsCategories}
                    presetValues={listOfOfficeHours}
                    //TODO: Convert this value to a configurable variable
                    maxItems={6}
                    updateItems={(newList: string[]) => {
                      updateableUser.LearnerProfile.NeedsCategories = newList;
                      updateProfile();
                    }}
                  />
                </GridItem>
                <GridItem colSpan={9} h={"100px"} />
                <GridItem colSpan={9} h={"202px"}>
                  <FormTextArea
                    label={"Goals & Interests"}
                    placeholder={"Explain the goal and interests you wish to explore through advising, e.g. I need help in improving my public speaking, I want to find out more about consulting, etc. You can update this box anytime."}
                    value={updateableUser.LearnerProfile.Needs}
                    onChange={(value: any) => {
                      updateableUser.LearnerProfile.Needs = value;
                    }}
                  />
                </GridItem>



                <GridItem pt={"42px"} colSpan={3} h={"130px"}>
                  <OikosButton
                    buttonText={"Save Changes"}
                    onClick={updateProfile}
                  />
                </GridItem>
              </Grid>
            </ProfileContentBox>

            {/*{loaded && <ProfileContentBox*/}
            {/*  title={"Payment Methods"}*/}
            {/*  ref={paymentRef}*/}
            {/*>*/}
            {/*  <Grid templateColumns="repeat(9, 1fr)">*/}
            {/*    {paymentMethods.map((paymentMethod: any, index: any) => {*/}
            {/*      return (*/}
            {/*        <>*/}
            {/*          <GridItem colSpan={1} h={"130px"}>*/}
            {/*            {paymentMethod.brand}*/}
            {/*          </GridItem>*/}
            {/*          <GridItem colSpan={1} h={"130px"}>*/}
            {/*            {paymentMethod.country}*/}
            {/*          </GridItem>*/}
            {/*          <GridItem colSpan={3} h={"130px"}>*/}
            {/*            {"**** **** **** " + paymentMethod.last4}*/}
            {/*          </GridItem>*/}
            {/*          <GridItem colSpan={2}>*/}
            {/*            {paymentMethod.exp_month + "/" + paymentMethod.exp_year}*/}
            {/*          </GridItem>*/}
            {/*        </>*/}
            {/*      )*/}
            {/*    })}*/}
            {/*    <GridItem pt={"42px"} colSpan={3} h={"130px"}>*/}
            {/*      <AddCreditCardDrawer*/}
            {/*        UserID={oikosUser.ID}*/}
            {/*      />*/}
            {/*    </GridItem>*/}

            {/*  </Grid>*/}
            {/*</ProfileContentBox>}*/}
          </Stack>
        </GridItem>
      </Grid>

    </>
  )
}

export default LearnerProfile;
