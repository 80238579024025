import { useAuth } from "../Hooks/useAuth";
import { Grid, GridItem, useToast, Text } from "@chakra-ui/react";
import React from "react";
import { FormInput } from "../OikosDesignSystem/Components/FormInput";
import OikosButton from "../OikosDesignSystem/OikosButton";
import ProfileContentBox from "./ProfileContentBox";

type Props = {
  children?: React.ReactNode;
};

const UpdatePasswordCard = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { oikosUser, changePassword } = useAuth();
    const toast = useToast();

    const [currentPassword, setCurrentPassword] = React.useState<string>("");
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");

    const passwordRequirements = {
      length: "Password must be at least 8 characters long",
      uppercase: "Password must contain at least one uppercase letter",
      lowercase: "Password must contain at least one lowercase letter",
      number: "Password must contain at least one number",
      character: "Password must contain at least one character",
    };

    const [passwordErrors, setPasswordErrors] = React.useState<string[]>([
      passwordRequirements.length,
      passwordRequirements.uppercase,
      passwordRequirements.lowercase,
      passwordRequirements.number,
      passwordRequirements.character,
    ]);


    const handleCheckPasswordRequirements = () => {
      let errors = [];

      if (newPassword.length < 8) {
        errors.push(passwordRequirements.length);
      }

      if (!/[A-Z]/.test(newPassword)) {
        errors.push(passwordRequirements.uppercase);
      }

      if (!/[a-z]/.test(newPassword)) {
        errors.push(passwordRequirements.lowercase);
      }

      if (!/[0-9]/.test(newPassword)) {
        errors.push(passwordRequirements.number);
      }

      if (!/[a-zA-Z]/.test(newPassword)) {
        errors.push(passwordRequirements.character);
      }

      setPasswordErrors(errors)
    }


    const handleChangePassword = () => {
      // let errors = [];
      //
      // if (newPassword.length < 8) {
      //   errors.push(passwordRequirements.length);
      // }
      //
      // if (!/[A-Z]/.test(newPassword)) {
      //   errors.push(passwordRequirements.uppercase);
      // }
      //
      // if (!/[a-z]/.test(newPassword)) {
      //   errors.push(passwordRequirements.lowercase);
      // }
      //
      // if (!/[0-9]/.test(newPassword)) {
      //   errors.push(passwordRequirements.number);
      // }
      //
      // if (!/[a-zA-Z]/.test(newPassword)) {
      //   errors.push(passwordRequirements.character);
      // }
      //
      // setPasswordErrors(errors);

      if (passwordErrors.length > 0) {
        return;
      }

      if (newPassword !== confirmPassword) {
        toast({
          title: "Error Updating Password",
          description: "New password and confirmation password do not match.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      changePassword(currentPassword, newPassword).then((res: any) => {
        if (res.success) {
          toast({
            title: "Password Updated Successfully",
            description: "Your password has been updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          toast({
            title: "Error Updating Password",
            description: res.message,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        }
      })
    };

    return (
      <ProfileContentBox title={"Change Password"} ref={ref}>
        <Grid templateColumns="repeat(9, 1fr)">
          <GridItem colSpan={7} h={"130px"}>
            <FormInput
              label={"Current Password"}
              type={"password"}
              onChange={(value: any) => {
                setCurrentPassword(value);
              }}
            />
          </GridItem>
          <GridItem colSpan={7}>
            <FormInput
              label={"New Password"}
              type={"password"}
              onChange={(value: any) => {
                setNewPassword(value);
                handleCheckPasswordRequirements()
              }}
            />
            <Text color={passwordErrors.includes(passwordRequirements.length) ? "red" : "green"}>{passwordRequirements.length}</Text>
            <Text color={passwordErrors.includes(passwordRequirements.uppercase) ? "red" : "green"}>{passwordRequirements.uppercase}</Text>
            <Text color={passwordErrors.includes(passwordRequirements.lowercase) ? "red" : "green"}>{passwordRequirements.lowercase}</Text>
            <Text color={passwordErrors.includes(passwordRequirements.number) ? "red" : "green"}>{passwordRequirements.number}</Text>
            <Text color={passwordErrors.includes(passwordRequirements.character) ? "red" : "green"}>{passwordRequirements.character}</Text>
          </GridItem>
          {passwordErrors.length === 0 && (
            <GridItem pt={"42px"} colSpan={7} h={"130px"}>
              <FormInput
                label={"Confirm New Password"}
                type={"password"}
                onChange={(value: any) => {
                  setConfirmPassword(value);
                }}
              />
            </GridItem>
          )}
          <GridItem pt={"42px"} colSpan={3} h={"130px"}>
            <OikosButton
              buttonText={"Update Password"}
              onClick={handleChangePassword}
            />
          </GridItem>
        </Grid>
      </ProfileContentBox>
    );
  }
);

export default UpdatePasswordCard;
