import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Text, useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {useAuth} from "../Hooks/useAuth";
import OikosButton from "../OikosDesignSystem/OikosButton";


const PasswordReset = () => {
  const [email, setEmail] = React.useState<string>('');
  const [isEmailSent, setEmailSent] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const { resetPassword } = useAuth();

  const toast = useToast();

  const handlePasswordReset = async () => {

    resetPassword(email).then((res) => {
      setEmailSent(true);
      toast({
        title: "Password Reset Email Sent",
        description: "Please check your email for a link to reset your password.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    })
    .catch((err) => {
      toast({
        title: "Error Resetting Password",
        description: "" + err,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    });
  };

  return (
    <Flex maxW="100%" py={{ base: '12', md: '24' }} justifyContent={'center'}>
      <Box w={640} pb={'80px'} bg={'neutral-100'} rounded={20} shadow={'md'}>
        <Flex
          flexDirection="column" // Stack children vertically
          alignItems="center"    // Centers children horizontally
          justifyContent="center" // Centers children vertically
          height="100%"          // Takes full available height
        >
          <Text pt={10} fontFamily={"lato"} color={"neutral-800"} fontWeight={"bold"} fontSize={"38"}>
            Password Reset
          </Text>
          {isEmailSent ? (
            <Stack spacing={"40px"}>
            <Text pt={10} fontFamily={"lato"} color={"neutral-800"} fontWeight={"light"} fontSize={"24"}
              width={"540px"}
            >
              A password reset link has been sent to your email. Please check your
              inbox and follow the instructions.
            </Text>
              <Button
                variant="primary"
                bg={'primary-color'}
                color={'neutral-100'}
                fontFamily={'lato'}
                fontWeight={'bold'}
                fontSize={'18'}
                h={65}
                onClick={() => navigate('/confirmpasswordreset')}
              >
                Insert Code
              </Button>
            </Stack>
          ) : (
            <Stack spacing="5" width={470} justifyContent={"center"}>
              <FormControl pt={10}>
                <Input
                  id="email"
                  h={65}
                  placeholder="Email Address"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>

              <OikosButton
                buttonText={"Send Password Verification Code"}
                onClick={handlePasswordReset}
              />
              <OikosButton
                buttonText={"I have a verification code"}
                variant={"outline"}
                onClick={() => navigate('/confirmpasswordreset')}
              />
              <Text
                color="primary-color"
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate('/login')}
              >
                Back to Login
              </Text>
            </Stack>
          )}

        </Flex>
      </Box>
    </Flex>
  );
};

export default PasswordReset;
