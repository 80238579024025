import { useAuth } from "../Hooks/useAuth";
import { Grid, GridItem, useToast, Text } from "@chakra-ui/react";
import React from "react";
import { FormInput } from "../OikosDesignSystem/Components/FormInput";
import OikosButton from "../OikosDesignSystem/OikosButton";
import ProfileContentBox from "../Profile/ProfileContentBox";
import {useOikos} from "../SDK/OikosContext";

type Props = {
  children?: React.ReactNode;
};

const UpdateContactCard = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { oikosUser, changePassword } = useAuth();
    const [email, setEmail] = React.useState<string>(oikosUser.Email);
    // const [email, setEmail] = React.useState<string>(oikosUser.PhoneNumber);

    const {oikos} = useOikos();

    const toast = useToast();


    const handleUpdateContactDetails = () => {
      oikos.changeContactInfo(oikosUser.ID, email).then((res: any) => {
        if (res) {
          toast({
            title: "Contact Information Updated",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Contact Information Update Failed",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    }

    return (
      <ProfileContentBox title={"Change Contact Information"} ref={ref}>
        <Grid templateColumns="repeat(9, 1fr)">
          <GridItem colSpan={7} h={"130px"}>
            <FormInput
              label={"Email"}
              value={email}
              onChange={(value: any) => {
                // setCurrentPassword(value);
              }}
            />
          </GridItem>
            {/*<GridItem colSpan={7} h={"130px"}>*/}
            {/*  <FormInput*/}
            {/*    label={"Phone"}*/}
            {/*    onChange={(value: any) => {*/}
            {/*      // setCurrentPassword(value);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</GridItem>*/}

          <GridItem pt={"42px"} colSpan={3} h={"130px"}>
            <OikosButton
              buttonText={"Update Contact Details"}
              onClick={handleUpdateContactDetails}
            />
          </GridItem>
        </Grid>
      </ProfileContentBox>
    );
  }
);

export default UpdateContactCard;
