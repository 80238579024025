import React, { useState } from 'react';
import { Box, Avatar, Input, Icon, Spinner } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useOikos } from '../SDK/OikosContext';
import { useToast } from '@chakra-ui/react';

interface ProfileAvatarProps {
  user: OIKOS.user;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ user}) => {
  const [loading, setLoading] = useState(false);
  const { oikos } = useOikos();
  const toast = useToast();

  const handleProfilePictureChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setLoading(true);
      oikos.uploadProfilePicture(user.ID, file).then((response) => {
        user.Avatar = response;
        setLoading(false);
        // oikos.getProfileImage(userId).then((url) => {
        //   setAvatarB64(url);
        //   setLoading(false);
        // })
      }).catch((err) => {
        setLoading(false);
        toast({
          title: 'Error Updating Profile Picture',
          description: err,
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
      })
    }
  };



  return (
    <label>
      <Input
        type="file"
        accept="image/*"
        onChange={handleProfilePictureChange}
        display="none"
      />
      <Box position="relative">
        {loading ? (
          <Spinner
            position={"absolute"}
            left={'680px'}
            top={'100px'}
            // height={'-100px'}
          /> // Display a spinner or loading message while uploading
        ) : (
          <>
            <Avatar
              position={'absolute'}
              left={'580px'}
              height={'-100px'}
              w={'200px'}
              h={'200px'}
              rounded={20}
              name={user.DisplayName}
              src={`data:image;base64,${user.Avatar}`}
              cursor="pointer"
            />
            <Icon as={EditIcon} position="absolute" left="750px" bottom="-200px" boxSize="8" color="gray.500" cursor="pointer"/>
          </>
        )}
      </Box>
    </label>
  );
};

export default ProfileAvatar;
