import React, {useState} from 'react';
import {
  GridItem,
  Card,
  CardBody,
  HStack,
  Stack,
  Flex,
  IconButton,
  Checkbox,
  Grid,
  Text,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import OikosButton from "../OikosDesignSystem/OikosButton";
import {FormInput} from "../OikosDesignSystem/Components/FormInput";
import {DeleteIcon, EditIcon} from "@chakra-ui/icons";

type Props = {
  user: OIKOS.user
  updateFunction: () => void
}

const ExperienceManager: React.FC<Props> = ({user, updateFunction}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [experiences, setExperiences] = useState<OIKOS.Experience[]>(user.Profile.Experience || []);
  const [currentExperience, setCurrentExperience] = useState({Position: '', Organization: '', StartDate: 1900, EndDate: 1900, CurrentPosition: false});
  const [currentExperienceIndex, setCurrentExperienceIndex] = useState<number | null>(null);

  const handleSave = () => {
    if (currentExperienceIndex !== null) {
      // Update the experience at the current index
      let updatedExperiences = [...experiences];
      updatedExperiences[currentExperienceIndex] = currentExperience;
      setExperiences(updatedExperiences);
      user.Profile.Experience = updatedExperiences;
    } else {
      // Add a new experience
      setExperiences([...experiences, currentExperience]);
      user.Profile.Experience = [...experiences, currentExperience];
    }
    setCurrentExperience({Position: '', Organization: '', StartDate: 1900, EndDate: 1900, CurrentPosition: false});
    updateFunction();
    onClose();
  };

  const handleDelete = (index: number) => {
    let newExperiences = (prevExperiences: OIKOS.Experience[]) => prevExperiences.filter((_: OIKOS.Experience, i: number) => i !== index)
    let updatedExperiences = newExperiences(experiences);
    setExperiences(updatedExperiences);
    user.Profile.Experience = updatedExperiences;
    updateFunction()
  };

  const handleEditExperience = (index: number) => {
    setCurrentExperienceIndex(index);
    setCurrentExperience(experiences[index]);
    onOpen();
  }

  const isEducator = () => {
    return user.UserType === "EDUCATOR";
  }

  return (
    <Grid templateColumns="repeat(9, 1fr)">
      {experiences && experiences.map((exp, index) => (
        <GridItem colSpan={9} pt={4}>
          <Card
            direction={{base: 'column', sm: 'row'}}
            bg={"gray.100"}
            overflow='hidden'
            variant='outline'
          >
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Stack>
                <CardBody>
                  <Text fontSize={"32"} fontWeight={"bold"}>{exp.Position} | {exp.Organization}</Text>

                  <HStack>
                    <Text fontSize={"20"} fontWeight={"bold"}>
                      { exp.StartDate } - { exp.CurrentPosition ? "Current Position" : exp.EndDate }
                    </Text>
                  </HStack>

                </CardBody>
              </Stack>
              <HStack bg={"gray.400"} px={4}>
                <IconButton
                  aria-label="Edit"
                  icon={<EditIcon/>}
                  onClick={() => handleEditExperience(index)}
                />
                <IconButton
                  aria-label="Delete"
                  icon={<DeleteIcon/>}
                  onClick={() => handleDelete(index)}
                />
              </HStack>
            </Flex>
          </Card>
        </GridItem>
      ))}

      <GridItem pt={"42px"} colSpan={3} h={"130px"}>
        <OikosButton
          onClick={onOpen}
          buttonText={"Add Experience"}
        />
      </GridItem>

      <Drawer size="md" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton/>
            <DrawerHeader>Add New Experience</DrawerHeader>

            <DrawerBody>
              <Grid gap={8}>
                <FormInput label="Organization" value={currentExperience.Organization}
                           onChange={(value) => setCurrentExperience({...currentExperience, Organization: value})}/>
                <FormInput label="Position" value={currentExperience.Position}
                           onChange={(value) => setCurrentExperience({...currentExperience, Position: value})}/>


                <FormInput type="number" label="Start Year" value={"" + currentExperience.StartDate}
                           onChange={(value) => setCurrentExperience({...currentExperience, StartDate: parseInt(value)})}/>
                <Checkbox
                  size={"lg"}
                  colorScheme="blue"
                  isChecked={currentExperience.CurrentPosition}
                  onChange={(e) => setCurrentExperience(prevState => ({...prevState, CurrentPosition: e.target.checked}))}
                >
                  <Text fontSize="md">Current Position</Text>
                </Checkbox>

                {!currentExperience.CurrentPosition && <FormInput type="number" label="End Year" value={"" + currentExperience.EndDate}
                           onChange={(value) => setCurrentExperience({...currentExperience, EndDate: parseInt(value)})}/> }

              </Grid>
            </DrawerBody>

            <DrawerFooter>
              <HStack spacing={4}>
                <OikosButton
                  buttonText={"Save"}
                  onClick={handleSave}
                />
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Grid>
  );
};

export default ExperienceManager;
