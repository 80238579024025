// TimeSlotSelector.tsx
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Button,
  Text,
  VStack,
  Grid,
  GridItem,
  UseRadioProps,
} from '@chakra-ui/react';
import BookSessionDrawer from "./BookSessionDrawer";
import {
  ArrowLeftIcon,
  ArrowRightIcon
} from "@chakra-ui/icons"

import { useOikos } from '../SDK/OikosContext';
import {useAuth} from "../Hooks/useAuth";


export interface TimeSlot {
  start: string;
  end: string;
}

export interface DayTimeSlots {
  Day: string;
  // slots: TimeSlot[];
  Slots: string[];
}


interface TimeSlotSelectorProps {
  onTimeSlotChange: (selectedTimeSlot: TimeSlot | null) => void;
  duration: number;
  educator: OIKOS.user;
  learnerID: string;
  preview?: boolean;
}

interface TimeSlotRadioProps extends UseRadioProps {
  children: React.ReactNode;
}

// const TimeSlotRadio: React.FC<TimeSlotRadioProps> = (props) => {
//   const { getInputProps, getCheckboxProps } = useRadio(props);
//   const input = getInputProps();
//   const checkbox = getCheckboxProps();
//
//   return (
//     <HStack as="label"
//             align={"center"}
//             bg={"neutral-100"}
//             borderRadius="md"
//             boxShadow="md"
//     >
//
//       <Box
//         {...checkbox}
//         cursor="pointer"
//         borderWidth="1px"
//         _checked={{
//           bg: 'teal.600',
//           color: 'white',
//           borderColor: 'teal.200',
//         }}
//         _focus={{
//           boxShadow: 'outline',
//         }}
//         px={"10px"}
//         py={"2px"}
//       >
//         {props.children}
//       </Box>
//       <input {...input} />
//     </HStack>
//   );
// };

const TimeSlotSelector: React.FC<TimeSlotSelectorProps> = ({
                                                             onTimeSlotChange,
                                                             duration,
                                                             educator,
                                                             learnerID,
                                                              preview = false,
                                                           }) => {
  const [dayTimeSlots, setDayTimeSlots] = useState<DayTimeSlots[]>([]);
  // const [dayTimeSlots, setDayTimeSlots] = useState<string[]>([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot | null>(
    null,
  );
  const minimumOffset = 2;
  const [startDateOffset, setStartDateOffset] = useState<number>(minimumOffset);

  const decreaseStartDateOffset = (unit: 'days' | 'months' | 'weeks' | 'years') => {
    let offset = startDateOffset;
    if (unit === 'days') {
      offset -= 1;
    } else if (unit === 'months') {
      offset -= moment().daysInMonth();
    } else if (unit === 'weeks') {
      offset -= 7;
    } else if (unit === 'years') {
      offset -= 365;
    }
    if (offset < minimumOffset) {
      offset = minimumOffset;
    }
    setStartDateOffset(offset);
    fetchAndSetTimeSlots();
  }

  const increaseStartDateOffset = (unit: 'days' | 'months' | 'weeks' | 'years') => {
    let offset = startDateOffset;
    if (unit === 'days') {
      offset += 1;
    } else if (unit === 'months') {
      offset += moment().daysInMonth();
    } else if (unit === 'weeks') {
      offset += 7;
    } else if (unit === 'years') {
      offset += 365;
    }
    setStartDateOffset(offset);
    fetchAndSetTimeSlots();
  }
  const getStartDate = () => {
    return moment().add(startDateOffset, 'days').format('YYYY-MM-DD');
  }

  const getStartMonth = () => {
    return moment().add(startDateOffset, 'days').format('MMMM');
  }

  const getStartYear = () => {
    return moment().add(startDateOffset, 'days').format('YYYY');
  }

  const { oikos } = useOikos();
  const { oikosUser } = useAuth();

  const fetchAndSetTimeSlots = async () => {
    try {
      const timeSlots =  await oikos.getEducatorAvailability(educator.ID, getStartDate(), oikosUser.Profile.Timezone)
      console.log(timeSlots)
      setDayTimeSlots(timeSlots);
    } catch (error) {
      console.error('Error fetching time slots:', error);
    }
  };


  useEffect(() => {
    fetchAndSetTimeSlots();
  }, []);



  return (
    <Grid templateColumns={"repeat(42,1fr)"} w={"100%"} gap={"4px"}>
      <GridItem colSpan={1}>
        <Button
          minW={0}
          maxW={"10px"}
          height={"100%"}
          onClick={() => decreaseStartDateOffset('years')}
          leftIcon={<ArrowLeftIcon />}
        />
      </GridItem>
      <GridItem colSpan={40} bg={"neutral-300"}>
        <Text align={"center"} fontFamily={"lato"} fontSize="32" fontWeight="bold">
          {getStartYear()}
        </Text>
      </GridItem>
      <GridItem colSpan={1}>
        <Button
          minW={0}
          maxW={"10px"}
          height={"100%"}
          onClick={() => increaseStartDateOffset('years')}
          leftIcon={<ArrowRightIcon />}
        />
      </GridItem>

      <GridItem colSpan={1}>
        <Button
          minW={0}
          maxW={"10px"}
          height={"100%"}
          onClick={() => decreaseStartDateOffset('months')}
          leftIcon={<ArrowLeftIcon />}
        />
      </GridItem>
      <GridItem colSpan={40} bg={"neutral-300"}>
        <Text align={"center"} fontFamily={"lato"} fontSize="32" fontWeight="bold">
          {getStartMonth()}
        </Text>
      </GridItem>
      <GridItem colSpan={1}>
        <Button
          minW={0}
          maxW={"10px"}
          height={"100%"}
          onClick={() => increaseStartDateOffset('months')}
          leftIcon={<ArrowRightIcon />}
        />
      </GridItem>

      <GridItem colSpan={1}>
        <Button
          minW={0}
          maxW={"10px"}
          height={"100%"}
          onClick={() => decreaseStartDateOffset('days')}
          leftIcon={<ArrowLeftIcon />}
        />
      </GridItem>
        {dayTimeSlots.map((dayTimeSlot, dayIndex) => {
          const date = moment(dayTimeSlot.Day)
          const dayOfWeek = date.format('ddd')
          const dayOfMonth = date.format('DD')

          return (
            <GridItem colSpan={8} height={"100%"}>
            <VStack key={dayTimeSlot.Day} height={"100%"} align="center" spacing={1} bg={"neutral-300"} py={"5px"}>
              <VStack align={"left"} spacing={"-10px"}>
                <Text fontFamily={"lato"} fontSize="18" fontWeight="medium">
                  {dayOfWeek}
                </Text>
                <Text fontFamily={"lato"} fontSize="32" fontWeight="bold">
                  {dayOfMonth}
                </Text>
              </VStack>

            {dayTimeSlot.Slots.map((slot, slotIndex) => (
              <>
                <BookSessionDrawer
                  key={slotIndex}
                  startDate={slot}
                  duration={duration}
                  educator={educator}
                  preview={preview}
                />
              </>
            ))}
          </VStack>
            </GridItem>
        )})}
      <GridItem colSpan={1}>
        <Button
          minW={0}
          maxW={"10px"}
          height={"100%"}
          onClick={() => increaseStartDateOffset('days')}
          rightIcon={<ArrowRightIcon />}
        />
      </GridItem>
    </Grid>
  );

};

export default TimeSlotSelector;
