


export const listOfOfficeHours = [
  { key: '', label: '--' },
  { key: 'academicAdvising', label: 'Academic Advising' },
  { key: 'careerGuidance', label: 'Career Guidance' },
  { key: 'graduateProfessionalSchool', label: 'Graduate School Mentoring' },
  { key: 'cvReview', label: 'CV/Resume Review' },
  { key: 'interviewPrep', label: 'Interview Preparation' },
  { key: 'subjectExpertise', label: 'Subject/Industry Expertise' },
  { key: 'applicationReview', label: `Application Review` },
  { key: `essayFeedback`, label: `Writing/Essay Feedback` },
  { key: `infoInterview`, label:  `Informational Interview` },
  { key: `personalStatement`, label:`Personal Statement Review` },
  { key: `coverLetterReview`, label: `Cover Letter Review` },
  { key: `guidedReadings`, label: `Guided Readings` },
  { key: `proposalReview`, label: `Proposal Review` },
  { key: `researchHelp`, label: `Research/Thesis Help` },
  { key: `nonAcademicCareerHelp`, label: `Non-Academic Careers for PhDs and Graduate Students` }
]

// Preset list of languages
export const listOfLanguages = [
  { key: '', label: '--' },
  { key: 'english', label: 'English' },
  { key: 'spanish', label: 'Spanish' },
  { key: 'french', label: 'French' },
  { key: 'german', label: 'German' },
  { key: 'chinese', label: 'Chinese' },
];


export const listOfDisciplines = [
  { key: '', label: '--' },
  { key: 'arts', label: 'Arts & Humanities' },
  { key: 'law', label: 'Law' },
  { key: 'business', label: 'Business' },
  { key: 'socialSciences', label: 'Social Sciences' },
  { key: 'stem', label: 'STEM' },
  { key: 'education', label: 'Education' },
  { key: 'healthMedicine', label: 'Health & Medicine' },
]
