import React from "react";
import { Box } from "@chakra-ui/react";
import { Title } from "../OikosDesignSystem/Typography";

type ProfileContentBoxProps = {
  children: React.ReactNode;
  title: string;
} & Record<string, any>;

const ProfileContentBox = React.forwardRef<HTMLDivElement, ProfileContentBoxProps>(
  ({ title, children, ...props }, ref) => {
    return (
      <Box
        w={875}
        bg={"neutral-100"}
        rounded={20}
        shadow={"md"}
        ref={ref} // Attach the forwarded ref to the Box
      >
        <Box p={"88px"}>
          <Title>{title}</Title>
          <Box pt={"55px"}>{children}</Box>
        </Box>
      </Box>
    );
  }
);

export default ProfileContentBox;
