import React, { useContext } from "react";
import { Oikos } from "./Oikos";

export interface OikosContextType {
  oikos: Oikos;
}

export const OikosContext = React.createContext<OikosContextType>({
  oikos: new Oikos(),
});

export const useOikos = () => {
  const context = useContext(OikosContext);
  if (context === undefined) {
    throw new Error("useOikos must be used within a OikosProvider");
  }
  return {
    ...context
  };
}



export const OikosContextConsumer = OikosContext.Consumer;

