import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Input,
  Stack,
  HStack,
  Text,
  Tag,
  TagLabel,
  TagCloseButton, Flex
} from "@chakra-ui/react";
import { Page } from "../OikosDesignSystem/Layout/Page";
import { useOikos } from "../SDK/OikosContext";
import EducatorCard from "../Components/EducatorCard";
import SessionCard from "../Components/SessionCard";


export const DashboardCard = ({ title, children }: { title: string, children: React.ReactNode }) => {
  return (
    <Box
      padding={"20px"}
      rounded={"10px"}
      bg={"white"}
      boxShadow={"xl"}
    >
      <Stack spacing={"10px"}>
        <Text fontSize={"lg"} fontWeight={"extrabold"}>{title}</Text>
        {children}
      </Stack>
    </Box>
  )
}



export const MyDashboard = () => {
  const [ suggestedEducators, setSuggestedEducators ] = useState<any[]>([]);
  const [ loading, setLoading ] = useState<boolean>(true);

  const [ filters, setFilters ] = useState<string[]>([]);
  const [ searchTerms, setSearchTerms ] = useState<string[]>([]);

  const { oikos } = useOikos();

  useEffect(() => {
    setLoading(true);
    oikos.searchEducators(searchTerms, filters).then((newEducators) => {
      setSuggestedEducators(newEducators.slice(0,4));
      setLoading(false);
    });
  }, [filters]);

  const testUpcommingSessions = [
    {
      "ID": "526e1fde-3c13-469f-bf5c-3e0007e0f13e",
      "Educator": "3e59d197-2e07-4c4f-a6af-196ddab68e8c",
      "Learner": "a3b1d455-6620-421d-8609-a253889b6da4",
      "LearnerType": "B2C_LEARNER",
      "Institution": "",
      "Duration": 30,
      "StartTime": new Date("2024-10-18T06:30:00Z"),
      "EndTime": new Date("2024-10-18T07:00:00Z"),
      "Cost": {
        "Amount": 5000,
        "Currency": "USD"
      },
      "Status": 1,
      "Transactions": [
        {
          "OriginStatus": 0,
          "DestinationStatus": 1,
          "Timestamp": new Date("2024-03-11T17:41:03.537Z"),
          "User": "a3b1d455-6620-421d-8609-a253889b6da4"
        }
      ],
      "Payment": {
        "Amount": {
          "Amount": 0,
          "Currency": ""
        },
        "Currency": "",
        "PaymentIntendID": "pi_3OtCqRDapg5Vj4Ta3aTaUtui"
      },
      "URLEducator": "",
      "URLLearner": "",
      "EducatorAvatar": "",
      "LearnerAvatar": ""
    },
    {
      "ID": "526e1fde-3c13-469f-bf5c-3e0007e0f13e",
      "Educator": "3e59d197-2e07-4c4f-a6af-196ddab68e8c",
      "Learner": "a3b1d455-6620-421d-8609-a253889b6da4",
      "LearnerType": "B2C_LEARNER",
      "Institution": "",
      "Duration": 30,
      "StartTime": new Date("2024-10-16T06:30:00Z"),
      "EndTime": new Date("2024-10-16T07:00:00Z"),
      "Cost": {
        "Amount": 5000,
        "Currency": "USD"
      },
      "Status": 1,
      "Transactions": [
        {
          "OriginStatus": 0,
          "DestinationStatus": 1,
          "Timestamp": new Date("2024-03-11T17:41:03.537Z"),
          "User": "a3b1d455-6620-421d-8609-a253889b6da4"
        }
      ],
      "Payment": {
        "Amount": {
          "Amount": 0,
          "Currency": ""
        },
        "Currency": "",
        "PaymentIntendID": "pi_3OtCqRDapg5Vj4Ta3aTaUtui"
      },
      "URLEducator": "",
      "URLLearner": "",
      "EducatorAvatar": "",
      "LearnerAvatar": ""
    }
  ]


  const testPastSessions = [
    {
      "ID": "4c0348b5-e1c9-4708-b5d3-7043752127c6",
      "Educator": "3e59d197-2e07-4c4f-a6af-196ddab68e8c",
      "Learner": "a3b1d455-6620-421d-8609-a253889b6da4",
      "LearnerType": "B2C_LEARNER",
      "Institution": "",
      "Duration": 30,
      "StartTime": new Date("2024-02-27T23:30:00Z"),
      "EndTime": new Date("2024-02-28T00:00:00Z"),
      "Cost": {
        "Amount": 5000,
        "Currency": "USD"
      },
      "Status": 2,
      "Transactions": [
        {
          "OriginStatus": 0,
          "DestinationStatus": 1,
          "Timestamp": new Date("2024-02-23T15:29:50.209Z"),
          "User": "a3b1d455-6620-421d-8609-a253889b6da4"
        },
        {
          "OriginStatus": 1,
          "DestinationStatus": 2,
          "Timestamp": new Date("2024-02-24T04:16:20.522Z"),
          "User": "3e59d197-2e07-4c4f-a6af-196ddab68e8c"
        }
      ],
      "Payment": {
        "Amount": {
          "Amount": 0,
          "Currency": ""
        },
        "Currency": "",
        "PaymentIntendID": "pi_3On0h8Dapg5Vj4Ta17f1SFGy"
      },
      "URLEducator": "",
      "URLLearner": "",
      "EducatorAvatar": "",
      "LearnerAvatar": ""
    },
    {
      "ID": "4c0348b5-e1c9-4708-b5d3-7043752127c6",
      "Educator": "3e59d197-2e07-4c4f-a6af-196ddab68e8c",
      "Learner": "a3b1d455-6620-421d-8609-a253889b6da4",
      "LearnerType": "B2C_LEARNER",
      "Institution": "",
      "Duration": 30,
      "StartTime": new Date("2024-02-17T23:30:00Z"),
      "EndTime": new Date("2024-02-18T00:00:00Z"),
      "Cost": {
        "Amount": 5000,
        "Currency": "USD"
      },
      "Status": 2,
      "Transactions": [
        {
          "OriginStatus": 0,
          "DestinationStatus": 1,
          "Timestamp": new Date("2024-02-23T15:29:50.209Z"),
          "User": "a3b1d455-6620-421d-8609-a253889b6da4"
        },
        {
          "OriginStatus": 1,
          "DestinationStatus": 2,
          "Timestamp": new Date("2024-02-24T04:16:20.522Z"),
          "User": "3e59d197-2e07-4c4f-a6af-196ddab68e8c"
        }
      ],
      "Payment": {
        "Amount": {
          "Amount": 0,
          "Currency": ""
        },
        "Currency": "",
        "PaymentIntendID": "pi_3On0h8Dapg5Vj4Ta17f1SFGy"
      },
      "URLEducator": "",
      "URLLearner": "",
      "EducatorAvatar": "",
      "LearnerAvatar": ""
    }
  ]


  return (
    <Page title={"My Dashboard"} backgroundImage={"SearchBackground.svg"}>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <GridItem>
          <DashboardCard
            title={"Upcoming Sessions"}
          >
            <Flex zIndex={1}>
              <Stack spacing={"10px"} width={"100%"}>
                {testUpcommingSessions.map((ses) => {
                  return <SessionCard session={ses} refresh={() => {}} />
                })}
              </Stack>
            </Flex>
          </DashboardCard>
        </GridItem>
        <GridItem>
          <DashboardCard
            title={"Recent Sessions"}
          >
            <Flex zIndex={1}>
              <Stack spacing={"10px"} width={"100%"}>
                {testPastSessions.map((ses) => {
                  return <SessionCard session={ses} refresh={() => {}} />
                })}
              </Stack>
            </Flex>
          </DashboardCard>
        </GridItem>
        <GridItem colSpan={2}>
          <DashboardCard
            title={"Suggested Educators"}
          >
            loaded &&
            <Grid templateColumns="repeat(12, 1fr)" gap={3}>
            {suggestedEducators.map((educator) => {
                return (
                    <EducatorCard
                      educator={educator}
                    />
                )}
              )
            }
            </Grid>
          </DashboardCard>
        </GridItem>
        <GridItem>
          <DashboardCard
            title={"Statistics"}
          >
          </DashboardCard>
        </GridItem>
        <GridItem>
          <DashboardCard
            title={"Relevant Resources"}
          >
          </DashboardCard>
        </GridItem>
        <GridItem colSpan={2}>
          <DashboardCard
            title={"Documents"}
          >
          </DashboardCard>
        </GridItem>
      </Grid>
    </Page>
  );
};
