import {
  BoxProps,
  Flex,
} from '@chakra-ui/react'
import * as React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
// import { Logo } from './Logo'

const Layout = (props: BoxProps) => {

  return (
    <Flex direction="column" maxW={"100%"} w={"100%"}>
      <Navbar />
        <Flex maxW={"100%"} w={"100%"} justifyContent={"center"} bg={"neutral-200"}>
          {props.children}
        </Flex>
      <Footer />
    </Flex>
  )
}

export default Layout;
