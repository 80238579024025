import React, {useRef, useState} from "react";
import {InputGroup, Grid, GridItem, Stack, useToast, Button, Text} from "@chakra-ui/react";
import ProfileButtons from "./ProfileButtons";
import {useAuth} from "../Hooks/useAuth";
import ProfileContentBox from "./ProfileContentBox";
import {FormInput} from "../OikosDesignSystem/Components/FormInput";
import {FormSelect} from "../OikosDesignSystem/Components/FormSelect";
import OikosButton from "../OikosDesignSystem/OikosButton";
import {useOikos} from "../SDK/OikosContext";
import momentTZ from "moment-timezone";
import {FormTextArea} from "../OikosDesignSystem/Components/FormTextArea";
import ProfileAvatar from "./ProfileAvatar";
import ListManager from "../Components/ListManager";
import KeywordLabelManager from "../Components/KeywordLabelManager";
import {listOfOfficeHours, listOfDisciplines, listOfLanguages} from "../oikosConfig";
import {useNavigate} from "react-router-dom";
import EducationManager from "../Components/EducationManager";
import ExperienceManager from "../Components/ExperienceManager";


const EducatorProfile = (props: any) => {
  const {oikosUser} = useAuth();
  let updateableUser = oikosUser;
  const {oikos} = useOikos();
  const toast = useToast();

  const timeZonesList = momentTZ.tz.names();

  const educatorProfileRef = useRef(null);
  const offeringsRef = useRef(null);
  // const hourlyRateRef = useRef(null);
  const educationRef = useRef(null);
  const experienceRef = useRef(null);
  const expertiseRef = useRef(null);
  const integrationRef = useRef(null);

  const navigate = useNavigate();

  const [googleUser, setGoogleUser] = useState(null);

  const updateProfile = () => {

    oikos.updateUser(updateableUser)
      .then((res: any) => {
        toast({
          title: "Profile Updated Successfully",
          description: "Your profile has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((err: any) => {
        toast({
          title: "Error Updating Profile",
          description: "There was an error updating your Profile. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  const [loaded, setLoaded] = useState(false);
  if (!loaded) {
    oikos.getGoogleUserInfo(oikosUser.ID).then((res: any) => {
      setGoogleUser(res);
      setLoaded(true);
    }).catch((err: any) => {
      setGoogleUser(null)
      setLoaded(true);
    })
  }


  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)">
        {/*Left Column*/}
        <GridItem colSpan={3}>
          {/*<GridItem colSpan={3} position={"fixed"} height={"100vh"}>*/}
          <Stack spacing={"16px"} pt={"134px"}>
            <ProfileButtons title={"Personal Background"} destinationRef={educatorProfileRef}/>
            <ProfileButtons title={"Education"} destinationRef={educationRef}/>
            <ProfileButtons title={"Expertise"} destinationRef={expertiseRef}/>
            <ProfileButtons title={"Experience"} destinationRef={experienceRef}/>
            <ProfileButtons title={"How I Can Help"} destinationRef={offeringsRef}/>
            {/*<ProfileButtons title={"Hourly Rate"} destinationRef={hourlyRateRef}/>*/}
            <ProfileButtons title={"Calendar Integration"} destinationRef={integrationRef}/>
            <Button
              h={"70px"}
              w={"274px"}
              bg={"primary-color-1"}
              color={"neutral-100"}
              fontFamily={"lato"}
              fontWeight={"bold"}
              fontSize={18}
              shadow={"md"}
              onClick={() => {
                navigate("/educatorPreview/" + oikosUser.ID);
              }}
            >
              Preview Profile
            </Button>
          </Stack>
        </GridItem>

        {/*Right Column*/}
        <GridItem colSpan={9} position={"relative"}>
          <ProfileAvatar user={updateableUser}/>
          {/*<Avatar position={"absolute"} left={"580px"} height={"-100px"} w={"200px"} h={"200px"} rounded={20} name={updateableUser?.DisplayName} src={updateableUser?.Picture}/>*/}
          <Stack spacing={"24px"} pt={"70px"}>
            <ProfileContentBox title={"Personal Background"} ref={educatorProfileRef}>
              <Grid templateColumns="repeat(11, 1fr)">
                <GridItem colSpan={4} h={"130px"}>
                  <FormInput
                    label={"First Name"}
                    value={updateableUser.Name}
                    onChange={(value: any) => {
                      updateableUser.Name = value;
                    }}
                  />
                </GridItem>
                <GridItem colSpan={1}/>
                <GridItem colSpan={4}>
                  <FormInput
                    label={"Last Name"}
                    value={updateableUser.FamilyName}
                    onChange={(value: any) => {
                      updateableUser.FamilyName = value;
                    }}
                  />
                </GridItem>

                <GridItem colSpan={4} h={"130px"}>
                  <FormSelect label={"Title"}
                              options={[["mr", "Mr."], ["ms", "Ms."], ["mrs", "Mrs."], ["dr", "Dr."]]}
                              value={updateableUser.Profile.Title}
                              onChange={(value: any) => {
                                updateableUser.Profile.Title = value;
                              }}
                  />
                </GridItem>
                <GridItem colSpan={1}/>
                <GridItem colSpan={4}>
                  <FormSelect
                    label={"Preferred Pronouns"}
                    options={[["he", "He/Him"], ["she", "She/Her"], ["they", "They/Them"]]}
                    value={updateableUser.Profile.Pronouns}
                    onChange={(value: any) => {
                      updateableUser.Profile.Pronouns = value;
                    }}
                  />
                </GridItem>


                <GridItem colSpan={9} h={"130px"}>
                  <FormSelect
                    label={"Your Timezone"}
                    options={timeZonesList.map((tz) => [tz, tz])}
                    value={updateableUser.Profile.Timezone}
                    onChange={(value: any) => {
                      updateableUser.Profile.Timezone = value;
                    }}
                  />
                </GridItem>


                <GridItem colSpan={9} h={"202px"}>
                  <FormTextArea
                    label={"About Me"}
                    value={updateableUser.Profile.Bio}
                    onChange={(value: any) => {
                      updateableUser.Profile.Bio = value;
                    }}
                  />
                </GridItem>


                <GridItem colSpan={9} h={"130px"}>
                  <FormInput
                    label={"LinkedIn Profile"}
                    value={updateableUser.Profile.LinkedIn}
                    onChange={(value: any) => {
                      updateableUser.Profile.LinkedIn = value;
                    }}
                  />
                </GridItem>


                <GridItem colSpan={9} h={"130px"}>
                  <FormInput
                    label={"Personal Website/Web Profile"}
                    value={updateableUser.Profile.Website}
                    onChange={(value: any) => {
                      updateableUser.Profile.Website = value;
                    }}
                  />
                </GridItem>


                <GridItem pt={"42px"} colSpan={3} h={"130px"}>
                  <OikosButton
                    buttonText={"Save Changes"}
                    onClick={updateProfile}
                  />
                </GridItem>

              </Grid>
            </ProfileContentBox>


            <ProfileContentBox title={"Education"} ref={educationRef}>
              <Grid templateColumns="repeat(10, 1fr)">
                <GridItem colSpan={10}>
                  <EducationManager
                    user={updateableUser}
                    updateFunction={updateProfile}
                  />
                </GridItem>
                <GridItem colSpan={9} h={"130px"}/>


              </Grid>
            </ProfileContentBox>

            <ProfileContentBox title={"Expertise"} ref={expertiseRef}>
              <Grid templateColumns="repeat(10, 1fr)">

                <GridItem colSpan={9} h={"60px"}>
                  <Text fontFamily={"lato"} fontSize={20} fontWeight={"bold"} color={"neutral-800"}>
                    Expertise Keywords (subject, skills, etc.):
                  </Text>
                </GridItem>
                <GridItem colSpan={9}>
                  <KeywordLabelManager
                    labels={updateableUser.EducatorProfile.Keywords}
                    updateLabels={(newList: string[]) => {
                      updateableUser.EducatorProfile.Keywords = newList;
                      updateProfile();
                    }}
                  />
                </GridItem>

                <GridItem colSpan={9} h={"60px"}/>
                <GridItem colSpan={9} h={"60px"}>
                  <Text fontFamily={"lato"} fontSize={20} fontWeight={"bold"} color={"neutral-800"}>
                    Languages:
                  </Text>
                </GridItem>
                <GridItem colSpan={9}>
                  <ListManager
                    list={updateableUser.Profile.Languages}
                    presetValues={listOfLanguages}
                    updateItems={(newList: string[]) => {
                      updateableUser.Profile.Languages = newList;
                      updateProfile();
                    }}
                  />
                </ GridItem>

                <GridItem colSpan={9} h={"60px"}/>
                <GridItem colSpan={9} h={"60px"}>
                  <Text fontFamily={"lato"} fontSize={20} fontWeight={"bold"} color={"neutral-800"}>
                    Disciplinary Backgrounds:
                  </Text>
                </GridItem>
                <GridItem colSpan={9}>
                  <ListManager
                    list={updateableUser.EducatorProfile.Disciplines}
                    presetValues={listOfDisciplines}
                    updateItems={(newList: string[]) => {
                      updateableUser.EducatorProfile.Disciplines = newList;
                      updateProfile();
                    }}
                  />
                </ GridItem>
              </Grid>
            </ProfileContentBox>

            <ProfileContentBox title={"Experience"} ref={experienceRef}>
              <Grid templateColumns="repeat(10, 1fr)">
                {/* Empty fields and "plus" button */}
                <GridItem colSpan={10}>
                  <ExperienceManager
                    user={updateableUser}
                    updateFunction={updateProfile}
                  />
                </GridItem>
              </Grid>
            </ProfileContentBox>


            <ProfileContentBox title={"How I Can Help"} ref={offeringsRef}>

              <ListManager
                list={updateableUser.EducatorProfile.Categories}
                presetValues={listOfOfficeHours}
                //TODO: Convert this value to a configurable variable
                maxItems={6}
                updateItems={(newList: string[]) => {
                  updateableUser.EducatorProfile.Categories = newList;
                  updateProfile();
                }}
              />
            </ProfileContentBox>

            {/*<ProfileContentBox title={"Hourly Rate"} ref={hourlyRateRef}>*/}
            {/*  <Grid templateColumns="repeat(9, 1fr)">*/}

            {/*    <GridItem colSpan={3}>*/}
            {/*      <InputGroup>*/}

            {/*        <FormInput*/}
            {/*          label={"Hourly Rate ($ USD)"}*/}
            {/*          type={"number"}*/}
            {/*          value={"" + updateableUser.EducatorProfile.Price.Amount / 100}*/}
            {/*          onChange={(value: any) => {*/}
            {/*            updateableUser.EducatorProfile.Price.Amount = parseFloat(value) * 100;*/}
            {/*          }}*/}
            {/*        />*/}
            {/*        /!*<InputRightAddon children="$" />*!/*/}
            {/*      </InputGroup>*/}
            {/*    </GridItem>*/}
            {/*    <GridItem colSpan={4}/>*/}
            {/*    <GridItem pt={"42px"} colSpan={3} h={"130px"}>*/}
            {/*      <OikosButton*/}
            {/*        buttonText={"Save Changes"}*/}
            {/*        onClick={updateProfile}*/}
            {/*      />*/}
            {/*    </GridItem>*/}
            {/*  </Grid>*/}
            {/*</ProfileContentBox>*/}

            <ProfileContentBox title={"Integrations"} ref={integrationRef}>

              <Grid templateColumns="repeat(10, 1fr)">
                {/* Empty fields and "plus" button */}
                {googleUser &&
                  <GridItem colSpan={10}>
                    <Stack>
                      <Text fontFamily={"lato"} fontSize={20} fontWeight={"bold"} color={"neutral-800"}>
                        Google Calendar Integration with {googleUser["email"]}.
                      </Text>
                      <Text fontFamily={"lato"} fontSize={18} color={"neutral-800"}>
                        Appointments in your Google Calender will show as unavailable slots in Polis.
                      </Text>

                    </Stack>
                  </GridItem>
                }

                <GridItem colSpan={5} pt={10}>
                  <OikosButton
                    buttonText={"Connect Google Calendar"}
                    onClick={() => {
                      oikos.getGooleAuthURL()
                    }}
                  />
                </GridItem>
              </Grid>

            </ProfileContentBox>

          </Stack>
        </GridItem>
      </Grid>
    </>
  );
};

export default EducatorProfile;
